@use "sass/color";
@use "sass/mixin";
@use "sass/size";
@use "sass/font";
@use "../base";

.near-storage-withdraw-task,
.near-storage-deposit-task,
.near-storage-unregister-task,
.near-ft-transfer-task,
.near-ft-transfer-call-task,
.near-nft-transfer-task,
.near-nft-transfer-call-task,
.near-nft-approve-task,
.near-nft-revoke-task,
.near-mft-transfer-task,
.near-mft-transfer-call-task,
.near-deposit-and-stake-task,
.near-unstake-task,
.near-withdraw-task,
.near-wrap-task,
.near-unwrap-task {
    background: color.$lightest;
    @include base.task-theme(color.$text);
    * > a {
        color: darken(color.$blue, 20%) !important;
    }
}

.near-wrap-task-edit,
.near-unwrap-task-edit {
    .InfoField-content {
        display: flex;
        flex-flow: column nowrap;
        .entry {
            @include mixin.center-items(space-between, center);
            height: 2em;
            flex-flow: row nowrap;
            max-width: 100%;
        }
        .key {
            font-weight: 800;
            white-space: nowrap;
        }
        .value {
            font-family: font.$code;
            white-space: nowrap;
            overflow: hidden;
            text-overflow: ellipsis;
            margin-left: 2em;
        }
    }
}

.near-nft-approve-task-edit,
.near-nft-revoke-task-edit {
    .InfoField-content {
        display: flex;
        flex-flow: column nowrap;
        .approved-account-id {
            align-self: flex-end;
        }
    }
}

.MuiInputAdornment-root {
    width: fit-content;
    p {
        display: block !important;
        font-size: size.$small-text !important;
        color: color.$lightish;
    }
}
