@use "sass/color";
@use "sass/mixin";
@use "sass/font";
@use "../base";

$meta-pool-purple: #735de9;

.meta-pool-nslp-add-liquidity-task,
.meta-pool-nslp-remove-liquidity-task {
    background-color: $meta-pool-purple;
    background-image: url("../../app/static/meta-pool/MetaPool_symbol.svg");
    background-position-x: 0rem;
    background-position-y: -2rem;
    background-repeat: no-repeat;
    background-size: 12rem;
    @include base.task-theme(color.$light-text);
}

.meta-pool-nslp-add-liquidity-task-edit,
.meta-pool-nslp-remove-liquidity-task-edit {
    .InfoField-content {
        display: flex;
        flex-flow: column nowrap;
        .entry {
            @include mixin.center-items(space-between, center);
            height: 2em;
            flex-flow: row nowrap;
            max-width: 100%;
        }
        .key {
            font-weight: 800;
            white-space: nowrap;
        }
        .value {
            font-family: font.$code;
            white-space: nowrap;
            overflow: hidden;
            text-overflow: ellipsis;
            margin-left: 2em;
        }
    }
}
