@use "sass/size";

.Placeholder {
    display: flex;
    flex: 1;
    align-self: center;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: 100%;
}
