@use "sass/color";
@use "sass/size";

.PopupMenu {
    position: relative;

    svg {
        display: block;
    }

    &:hover &-content {
        visibility: visible !important;
    }

    &-content {
        visibility: hidden;
        position: absolute;
        left: size.$sidebar-width;
        padding-left: 0.5 * size.$gap;
        width: max-content;

        &.align-top {
            top: 0;
        }

        &.align-bottom {
            bottom: 0;
        }

        ul {
            padding: 0;
            margin-left: 0;
            background-color: color.$black;
            color: color.$light-text;
            font-size: size.$text;
            border-radius: size.$task-radius;
            list-style: none;
            overflow: hidden;

            li {
                min-width: 100px;
                padding: 0.25 * size.$gap 0.5 * size.$gap;
                cursor: pointer;

                &:last-of-type {
                    padding-bottom: 0.5 * size.$gap;
                }

                &:first-of-type {
                    padding-top: 0.5 * size.$gap;
                }

                &:hover {
                    background-color: rgba(color.$light, 0.1);
                }

                .MuiChip-root {
                    background-color: color.$purple;
                    font-size: 0.8 * size.$smaller-text;
                    height: auto;
                    margin-left: 1ch;
                }
            }
        }
    }
}
