@use "sass/color";
@use "sass/size";
@use "sass/font";

.InfoField {
    width: 100%;

    box-shadow: inset 0px 0px 30px 0px rgba(color.$white, 0.1);
    border-radius: 0.25 * size.$task-radius;

    // match Mui values
    margin: 8px 0 4px 0;

    color: color.$white;
    font-family: font.$text;
    font-size: size.$small-text;
    line-height: size.$text;

    &-content {
        display: flex;
        flex-flow: column;
        padding: 8.5px 14px;
        img,
        svg {
            margin: auto;
            max-height: size.$task-height;
            max-width: 100%;
            cursor: pointer;
        }
    }

    &.roundtop {
        border-top-left-radius: 0.75 * size.$task-radius !important;
        border-top-right-radius: 0.75 * size.$task-radius !important;
    }
    &.roundbottom {
        border-bottom-left-radius: 0.75 * size.$task-radius !important;
        border-bottom-right-radius: 0.75 * size.$task-radius !important;
    }
}
