@use "sass/mixin";
@use "sass/size";
@use "sass/color";
@use "sass/base";

.Builder {
    @include mixin.center-items(flex-start);
    gap: size.$gap;

    &-form {
        width: 100%;
    }
    &-droppable {
        flex: 1;
        width: 100%;
        position: relative;
    }
    &-selector {
        @include mixin.no-scrollbar;
        position: absolute;
        left: 0;
        z-index: 2;
        width: size.$task-width;
        max-height: 100%;
        overflow-y: scroll;
        border-radius: size.$task-radius;
        .column-container {
            width: 100%;
            height: 100%;
            padding: 0 !important;
            margin: 0 !important;
            background-color: transparent !important;
            .tasks-wrapper {
                flex: 1 1 0;
                box-shadow: inset 0px 0px 30px 0px rgba(color.$white, 0.1);
                .task-wrapper:last-of-type {
                    margin-bottom: 0 !important;
                }
                .icon {
                    display: none;
                }
                .task-container {
                    outline: none !important;
                }
            }
        }
    }
}
