@use "sass/color";
@use "sass/size";
@use "sass/font";
@use "sass/mixin";

@mixin backdrop {
    @include cb-scale(0);
    // display: none; // TODO: fix "party mode"
    position: absolute;
    width: 4 * size.$gap;
    height: 4 * size.$gap;
    top: -2 * size.$gap;
    border-radius: 100%;
    z-index: 0;
}

@mixin cb-scale($factor) {
    zoom: $factor; /* IE */
    -moz-transform: scale($factor); /* Firefox */
    -moz-transform-origin: center center;
    -o-transform: scale($factor); /* Opera */
    -o-transform-origin: center center;
    -webkit-transform: scale($factor); /* Safari And Chrome */
    -webkit-transform-origin: center center;
    transform: scale($factor); /* Standard Property */
    transform-origin: center center; /* Standard Property */
}

@mixin task-theme($contrast) {
    position: relative;
    display: flex;
    flex-flow: column nowrap;
    width: 100%;
    min-height: size.$task-height;
    border-radius: size.$task-radius;
    overflow: hidden;
    * {
        color: $contrast;
    }
    .name {
        @include mixin.center-items;
        font-size: size.$text;
        padding-top: 0.25 * size.$gap;
        padding-bottom: 0.25 * size.$gap;
        * {
            color: $contrast;
        }
        .icon {
            @include mixin.icon;
            fill: $contrast;
            cursor: pointer;
            opacity: 0;
            transition: opacity 100ms ease;
            z-index: 1;
            &.edit {
                position: absolute;
                right: 0.5 * size.$gap;
                &:hover + .edit-pseudo {
                    @include cb-scale(1);
                    transition: all 300ms ease;
                }
            }
            &.delete {
                position: absolute;
                left: 0.5 * size.$gap;
                &:hover + .delete-pseudo {
                    @include cb-scale(1);
                    transition: all 300ms ease;
                }
            }
            &.duplicate {
                position: absolute;
                right: 1.5 * size.$gap;
                &:hover + .duplicate-pseudo {
                    @include cb-scale(1);
                    transition: all 300ms ease;
                }
            }
        }
        .delete-pseudo {
            @include backdrop;
            left: -1 * size.$gap;
            background-color: rgba(color.$red, 0.8);
        }
        .edit-pseudo {
            @include backdrop;
            right: -1 * size.$gap;
            background-color: rgba(color.$blue, 0.8);
        }
        .duplicate-pseudo {
            @include backdrop;
            right: 0;
            background-color: rgba(color.$purple, 0.8);
        }
    }
    &:hover > .name > .icon {
        opacity: 1;
        transition: opacity 100ms ease;
    }
    .data-container {
        @include mixin.no-scrollbar;
        flex: 1;
        max-height: 100%;
        overflow-y: scroll;
        padding: 0.5 * size.$gap;
        background-color: rgba(color.$light, 0.25);
        z-index: 1;
        .addr {
            display: flex;
            width: calc(100% - size.$gap);
            padding: 0 0.5 * size.$gap;
            flex-flow: row nowrap;
            justify-content: space-between;
            margin-bottom: -0.3rem;
            font-size: size.$small-text;
            span {
                white-space: nowrap;
            }
            .code {
                @include mixin.no-wrap;
                padding-left: 1.5rem;
                line-height: 1.85rem;
                font-family: font.$code;
                font-size: size.$smaller-text;
            }
        }
        .action-data-container {
            margin-top: 0.5 * size.$gap;
            padding: 0.5 * size.$gap;
            border: 1px solid rgba($contrast, 0.2);
            border-radius: 0.25 * size.$task-radius;
            pre.code {
                @include mixin.no-scrollbar;
                padding: 0.5rem;
                margin: 0.5rem 0;
                background-color: rgba(color.$lightest, 1);
                border-radius: 0.25 * size.$task-radius;
                font-family: font.$code;
                font-size: size.$smaller-text;
                overflow-x: scroll;
                color: color.$text;
            }
            p {
                display: flex;
                width: 100%;
                flex-flow: row nowrap;
                justify-content: space-between;
                margin-bottom: -0.3rem;
                font-size: size.$small-text;
                span {
                    white-space: nowrap;
                }
                .code {
                    @include mixin.no-wrap;
                    padding-left: 1.5rem;
                    line-height: 1.85rem;
                    font-family: font.$code;
                    font-size: size.$smaller-text;
                }
                &.expandable {
                    flex-wrap: wrap;
                }
            }
            &:first-of-type {
                border-top-left-radius: 0.75 * size.$task-radius !important;
                border-top-right-radius: 0.75 * size.$task-radius !important;
            }
            &:last-of-type {
                border-bottom-left-radius: 0.75 * size.$task-radius !important;
                border-bottom-right-radius: 0.75 * size.$task-radius !important;
            }
        }
    }
    &.has-errors:not(.is-edited) {
        outline: 8px solid color.$red;
        outline-offset: -4px;
        border-radius: size.$task-radius;
    }
    &.is-edited {
        outline: 8px solid color.$blue;
        outline-offset: -4px;
        border-radius: size.$task-radius;
    }
}

form.edit {
    @include mixin.full;
    @include mixin.center-items(flex-start);
    @include mixin.no-scrollbar;
    flex-flow: column nowrap;
    padding: 0 size.$gap;
    width: calc(100% - 2 * size.$gap);
    overflow-y: scroll;

    .empty-line {
        width: 100%;
        height: 1em;
    }

    .protocol {
        justify-self: flex-end;
        @include mixin.center-items;
        padding: size.$gap;
        margin-top: auto;
        gap: 1ch;
        color: rgba(color.$light-text, 0.5) !important;
        font-size: size.$small-text;
        cursor: pointer;
        .logo {
            font-size: size.$large-text;
            height: size.$large-text;
        }
        span {
            white-space: nowrap;
        }
        &:hover {
            text-decoration: underline;
            color: color.$light-text !important;
        }
    }
}

.MuiMenuItem-root {
    font-size: size.$small-text !important;
}
