@use "sass/color";
@use "sass/size";
@use "sass/font";
@use "sass/mixin";

.light-textfield {
    &.MuiTextField-root {
        .MuiInputLabel-root,
        .MuiFilledInput-root.Mui-focused {
            &:not(.Mui-error) {
                color: color.$light-text !important;
            }
            &.Mui-error {
                color: color.$red !important;
            }
        }
        .MuiFilledInput-root,
        .MuiFilledInput-root:hover {
            background-color: rgba(color.$white, 0.06) !important;
            &::before,
            &:hover::before {
                border-bottom: 1px solid rgba(color.$white, 0.42);
            }
            &::after {
                border-bottom: 2px solid color.$white;
            }
            &.Mui-error {
                &::before,
                &:hover::before {
                    border-bottom: 1px solid rgba(color.$red, 0.42) !important;
                }
                &::after {
                    border-bottom: 2px solid color.$red !important;
                }
            }
            input,
            textarea {
                color: color.$white;
                font-size: size.$text;
            }
            textarea {
                @include mixin.no-scrollbar;
                white-space: pre;
                overflow-x: scroll !important;
            }
        }
        *:not(input) {
            color: color.$light-text;
            font-size: 1em;
        }
        font-size: 1.8rem;
    }
}

.spacer {
    flex: 1;
}

.loader {
    display: flex;
    flex: 1;
    align-self: center;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: 100%;

    &::after {
        content: " ";
        display: block;
        width: 64px;
        height: 64px;
        border-radius: 100%;
        border: 6px solid color.$lightish;
        border-color: color.$lightish transparent color.$lightish transparent;
        animation: lds-dual-ring 1.2s linear infinite;
    }

    @keyframes lds-dual-ring {
        0% {
            transform: rotate(0deg);
        }
        100% {
            transform: rotate(360deg);
        }
    }
}
