@use "sass/mixin";
@use "sass/size";
@use "sass/color";
@use "sass/animation";

@mixin tutorial {
    content: " ";
    position: absolute;
    height: 40vh;
    top: 30vh;
    background-size: contain;
    background-repeat: no-repeat;
    opacity: 0.2;
    filter: none;
    transition: all 1s ease;
}

.layout-wrapper {
    @include mixin.page-wrapper;
    display: flex;
    flex-flow: row nowrap;
    .layout-container {
        @include mixin.full;
        @include mixin.center-items(center, flex-start);
        flex: 1 1 0;
        flex-flow: row nowrap;
        padding-left: size.$gap;
        width: auto;
        &[tutorial="yes"]::before {
            @include tutorial;
            width: 28%;
            left: 15%;
            background-image: url("../../app/static/sequence.svg");
            background-position: left;
        }
        &[tutorial="no"]::before {
            @include tutorial;
            width: 28%;
            left: 15%;
            background-image: url("../../app/static/sequence.svg");
            background-position: left;
            opacity: 0;
            filter: blur(5px);
        }
        &[tutorial="yes"]::after {
            @include tutorial;
            width: 35%;
            right: 15%;
            background-image: url("../../app/static/parallel.svg");
            background-position: right;
        }
        &[tutorial="no"]::after {
            @include tutorial;
            width: 35%;
            right: 15%;
            background-image: url("../../app/static/parallel.svg");
            background-position: right;
            opacity: 0;
            filter: blur(5px);
        }
    }
    .empty-container {
        flex-shrink: 0;
        flex-basis: calc(4 * size.$gap + size.$task-width);
        transition: flex-basis animation.$menu-expand-time ease-out;
        &.expanded-empty {
            flex-basis: calc(2 * size.$gap + animation.$menu-expand-width);
            transition: flex-basis animation.$menu-expand-time ease-out;
        }
    }
}
