@use "sass/size";
@use "sass/mixin";
@use "sass/base";
@use "sass/color";

.placeholder {
    @include mixin.center-items;
    padding: 0 size.$gap;
    color: color.$light-text;
    font-size: size.$text;
    text-align: center;
    .huge-icon {
        font-size: 2 * size.$huge-text;
        margin-top: -2 * size.$gap;
        margin-bottom: size.$gap;
    }
    h3 > .icon {
        font-size: size.$large-text;
    }
}
