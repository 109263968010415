@use "sass/mixin";
@use "sass/size";

.IconLabel {
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 0.5 * size.$gap;
    width: fit-content;
    line-height: 0;

    &--reversed {
        flex-direction: row-reverse;
    }

    &--symbolic {
        line-height: 1;
    }

    &-icon {
        width: fit-content;
        height: fit-content;
        cursor: default !important;
        border-radius: 50%;
        box-shadow: 0px 5px 11px -3px rgba(0, 0, 0, 0.1);
        line-height: inherit;

        & > img,
        & > svg {
            width: 1.5 * size.$gap;
            height: 1.5 * size.$gap;
        }
    }

    &-label {
        font-weight: bold;
        line-height: 0;
    }
}
