@use "sass/color";
@use "sass/size";
@use "sass/mixin";
@use "sass/animation";

.Menu {
    position: fixed;
    top: size.$gap;
    right: size.$gap;
    width: size.$menu-width;
    height: calc(100% - 2 * size.$gap);
    min-width: calc(size.$task-width + 2 * size.$gap);
    background-color: color.$black;
    box-shadow: 1px 10px 20px 0 rgba(0, 0, 0, 0.2);
    border-radius: 1.75 * size.$task-radius;
    z-index: 2;
    transition: width animation.$menu-expand-time ease-out;
    &-tabs {
        height: calc(100% - size.$gap);
        &-buttonsPanel {
            box-shadow: inset 0px 0px 30px 0px rgba(color.$white, 0.1);
            border-radius: size.$task-radius;
            margin: size.$gap;
            width: calc(100% - 2 * size.$gap);

            .Tabs-item-button {
                flex: 1;
            }
        }
        &-contentSpace {
            .Tabs-item-panel {
                &.is-active {
                    flex-flow: column;
                    gap: size.$gap;
                    height: calc(100% - size.$Tabs-layout-buttonsPanel-height);

                    & > div {
                        display: flex;
                        flex-flow: column nowrap;
                        height: calc(100% - size.$gap);
                        width: calc(100% - (2 * size.$gap));
                        padding: 0 size.$gap size.$gap size.$gap;
                    }
                }
            }
        }
    }
    &--expanded {
        width: animation.$menu-expand-width;
        transition: width animation.$menu-expand-time ease-out;
    }
}

.toggle-size {
    position: absolute;
    left: calc(-0.5 * (size.$large-text + size.$gap));
    top: calc(50% - 0.5 * size.$large-text);
    width: calc(size.$large-text + size.$gap);
    transition: transform animation.$menu-expand-time linear;

    .icon {
        scale: 1.2;
    }

    &.expand {
        transform: scaleX(1);

        .icon {
            @include mixin.icon;
            font-size: size.$large-text;
        }
    }

    &.collapse {
        transform: scaleX(-1);
        .icon {
            @include mixin.light-icon;
            font-size: size.$large-text;
        }
    }
}
