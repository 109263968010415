@use "sass/mixin";
@use "sass/size";
@use "sass/color";

.Tabs-item-button {
    height: 100%;
    padding: 0 size.$gap;
    font-size: size.$text;
    background-color: color.$lightest;
    color: color.$text;

    &.is-active,
    &:hover,
    &:focus {
        transition: all 400ms ease;
        transition-property: background-color, color;
    }

    &.is-active {
        color: color.$light-text;
    }

    &.is-active,
    &.is-active:hover {
        background-color: color.$darkest;
    }

    &:hover {
        background-color: rgba(color.$dark, 0.2);
    }

    &--invertedColors {
        background-color: transparent;
        color: color.$light-text;

        &.is-active {
            color: color.$text;
        }

        &.is-active,
        &.is-active:hover {
            background-color: color.$lightest;
        }

        &:hover {
            background-color: rgba(color.$lightest, 0.1);
        }
    }
}

.Tabs-item-panel {
    display: none;
    width: 100%;
    height: 100%;
    margin: 0 auto;

    &.is-active {
        @include mixin.center-items;
    }
}
