@use "sass/size";
@use "sass/color";

@mixin full {
    position: relative;
    width: 100%;
    height: 100%;
    margin: 0;
    padding: 0;
}

@mixin page-wrapper {
    position: relative;
    height: 100%;
    width: calc(100% - size.$sidebar-width);
    left: size.$sidebar-width;
    &::before {
        content: "";
        position: fixed;
        top: 0;
        left: 0;
        height: calc(2 * size.$gap + 0.5 * size.$large-text);
        min-width: 100%;
        background-color: color.$light;
    }
}

@mixin available-width {
    width: 100%;
    width: -moz-available; /* WebKit-based browsers will ignore this. */
    width: -webkit-fill-available; /* Mozilla-based browsers will ignore this. */
    width: fill-available;
}

@mixin center-items($x: center, $y: center) {
    display: flex;
    justify-content: #{$x};
    align-items: #{$y};
}

@mixin no-scrollbar {
    scrollbar-width: none;
    -ms-overflow-style: -ms-autohiding-scrollbar;
    &::-webkit-scrollbar {
        display: none;
    }
}

@mixin no-wrap {
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
}

@mixin popup {
    position: fixed;
    display: flex;
    justify-content: center;
    z-index: 2;
    gap: size.$gap;
    padding: 12.5vh 12.5%;
    width: 75%;
    height: 75vh;
    top: 0;
    left: 0;
    background-color: rgba(255, 255, 255, 0.87);
    button,
    .button-container {
        position: absolute;
        bottom: size.$gap;
        right: size.$gap;
    }
}

@mixin icon {
    color: color.$lightish;
    font-size: size.$large-text;
    &:hover,
    &:focus {
        color: color.$dark;
    }
}

@mixin light-icon {
    color: color.$lightish;
    font-size: size.$large-text;
    &:hover,
    &:focus {
        color: color.$lightest;
    }
}
