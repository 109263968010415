@use "sass/size";
@use "sass/color";
@use "sass/font";
@use "sass/mixin";

.Dialog {
    .MuiPaper-root {
        min-width: size.$task-width;
        background-color: color.$black;
        color: color.$light-text;
        border-radius: size.$task-radius;
        font-size: size.$text;
    }

    &-title {
        &.MuiDialogTitle-root {
            font-size: size.$large-text;
            font-weight: 800;
            font-family: font.$text;
            padding: size.$gap;
            padding-bottom: 0.5 * size.$gap;
        }
    }

    &-content {
        display: flex;
        flex-flow: column nowrap;
        gap: 0.5 * size.$gap;

        .MuiTextField-root {
            width: 100%;

            p.Mui-error {
                color: color.$red;
                font-weight: 800;
                font-family: font.$text;
                font-size: size.$small-text;

                &::before {
                    content: "Error: ";
                }
            }
        }
    }

    &-actions {
        &.MuiDialogActions-root {
            @include mixin.center-items(space-between, center);
            flex-flow: row nowrap;
            padding: size.$gap;
            padding-top: 0;
        }
    }
}
