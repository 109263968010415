@use "sass/size";
@use "sass/color";

.task-wrapper {
    width: size.$task-width;
    min-height: size.$task-height;
    border-radius: size.$task-radius;
    margin-bottom: size.$gap;
    overflow: hidden;
}
