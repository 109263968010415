$unit: 1.6rem;
$gap: 2.8rem;

$stroke: 3.2rem;

$smaller-text: 1.3rem;
$small-text: 1.4rem;
$text: 1.8rem;
$large-text: 2.3rem;
$huge-text: 9.6rem;

$task-height: 14.2rem;
$task-width: 34rem;
$task-radius: 2.4rem;

$menu-width: 0rem; // 25vw;

$Tabs-layout-buttonsPanel-height: 1.4 * $gap;
$sidebar-width: 2 * $gap;
