@import "https://fonts.googleapis.com/css2?family=Roboto+Mono&family=Titillium+Web:ital,wght@0,200;0,400;0,600;1,400&display=swap";
@import "https://fonts.googleapis.com/icon?family=Material+Icons";
@import "https://fonts.googleapis.com/css2?family=Roboto+Mono&family=Titillium+Web:ital,wght@0,200;0,400;0,600;1,400&display=swap";
@import "https://fonts.googleapis.com/icon?family=Material+Icons";
@import "https://fonts.googleapis.com/css2?family=Roboto+Mono&family=Titillium+Web:ital,wght@0,200;0,400;0,600;1,400&display=swap";
@import "https://fonts.googleapis.com/icon?family=Material+Icons";
@import "https://fonts.googleapis.com/css2?family=Roboto+Mono&family=Titillium+Web:ital,wght@0,200;0,400;0,600;1,400&display=swap";
@import "https://fonts.googleapis.com/icon?family=Material+Icons";
@import "https://fonts.googleapis.com/css2?family=Roboto+Mono&family=Titillium+Web:ital,wght@0,200;0,400;0,600;1,400&display=swap";
@import "https://fonts.googleapis.com/icon?family=Material+Icons";
@import "https://fonts.googleapis.com/css2?family=Roboto+Mono&family=Titillium+Web:ital,wght@0,200;0,400;0,600;1,400&display=swap";
@import "https://fonts.googleapis.com/icon?family=Material+Icons";
@import "https://fonts.googleapis.com/css2?family=Roboto+Mono&family=Titillium+Web:ital,wght@0,200;0,400;0,600;1,400&display=swap";
@import "https://fonts.googleapis.com/icon?family=Material+Icons";
@import "https://fonts.googleapis.com/css2?family=Roboto+Mono&family=Titillium+Web:ital,wght@0,200;0,400;0,600;1,400&display=swap";
@import "https://fonts.googleapis.com/icon?family=Material+Icons";
@import "https://fonts.googleapis.com/css2?family=Roboto+Mono&family=Titillium+Web:ital,wght@0,200;0,400;0,600;1,400&display=swap";
@import "https://fonts.googleapis.com/icon?family=Material+Icons";
@import "https://fonts.googleapis.com/css2?family=Roboto+Mono&family=Titillium+Web:ital,wght@0,200;0,400;0,600;1,400&display=swap";
@import "https://fonts.googleapis.com/icon?family=Material+Icons";
@import "https://fonts.googleapis.com/css2?family=Roboto+Mono&family=Titillium+Web:ital,wght@0,200;0,400;0,600;1,400&display=swap";
@import "https://fonts.googleapis.com/icon?family=Material+Icons";
@import "https://fonts.googleapis.com/css2?family=Roboto+Mono&family=Titillium+Web:ital,wght@0,200;0,400;0,600;1,400&display=swap";
@import "https://fonts.googleapis.com/icon?family=Material+Icons";
@import "https://fonts.googleapis.com/css2?family=Roboto+Mono&family=Titillium+Web:ital,wght@0,200;0,400;0,600;1,400&display=swap";
@import "https://fonts.googleapis.com/icon?family=Material+Icons";
@import "https://fonts.googleapis.com/css2?family=Roboto+Mono&family=Titillium+Web:ital,wght@0,200;0,400;0,600;1,400&display=swap";
@import "https://fonts.googleapis.com/icon?family=Material+Icons";
@import "https://fonts.googleapis.com/css2?family=Roboto+Mono&family=Titillium+Web:ital,wght@0,200;0,400;0,600;1,400&display=swap";
@import "https://fonts.googleapis.com/icon?family=Material+Icons";
@import "https://fonts.googleapis.com/css2?family=Roboto+Mono&family=Titillium+Web:ital,wght@0,200;0,400;0,600;1,400&display=swap";
@import "https://fonts.googleapis.com/icon?family=Material+Icons";
@import "https://fonts.googleapis.com/css2?family=Roboto+Mono&family=Titillium+Web:ital,wght@0,200;0,400;0,600;1,400&display=swap";
@import "https://fonts.googleapis.com/icon?family=Material+Icons";
@import "https://fonts.googleapis.com/css2?family=Roboto+Mono&family=Titillium+Web:ital,wght@0,200;0,400;0,600;1,400&display=swap";
@import "https://fonts.googleapis.com/icon?family=Material+Icons";
@import "https://fonts.googleapis.com/css2?family=Roboto+Mono&family=Titillium+Web:ital,wght@0,200;0,400;0,600;1,400&display=swap";
@import "https://fonts.googleapis.com/icon?family=Material+Icons";
@import "https://fonts.googleapis.com/css2?family=Roboto+Mono&family=Titillium+Web:ital,wght@0,200;0,400;0,600;1,400&display=swap";
@import "https://fonts.googleapis.com/icon?family=Material+Icons";
@import "https://fonts.googleapis.com/css2?family=Roboto+Mono&family=Titillium+Web:ital,wght@0,200;0,400;0,600;1,400&display=swap";
@import "https://fonts.googleapis.com/icon?family=Material+Icons";
@import "https://fonts.googleapis.com/css2?family=Roboto+Mono&family=Titillium+Web:ital,wght@0,200;0,400;0,600;1,400&display=swap";
@import "https://fonts.googleapis.com/icon?family=Material+Icons";
@import "https://fonts.googleapis.com/css2?family=Roboto+Mono&family=Titillium+Web:ital,wght@0,200;0,400;0,600;1,400&display=swap";
@import "https://fonts.googleapis.com/icon?family=Material+Icons";
@import "https://fonts.googleapis.com/css2?family=Roboto+Mono&family=Titillium+Web:ital,wght@0,200;0,400;0,600;1,400&display=swap";
@import "https://fonts.googleapis.com/icon?family=Material+Icons";
.FormLabel.MuiFormLabel-root {
  color: #edfdf9;
  font-size: 1.8rem;
}

.FormLabel.is-focused.Mui-focused {
  color: #edfdf9;
}

.CheckboxField {
  border-radius: .6rem;
  flex-flow: wrap;
  justify-content: flex-start;
  align-items: center;
  width: 100%;
  margin-top: 8px;
  margin-bottom: 4px;
  display: flex;
  box-shadow: inset 0 0 30px #edfdf91a;
}

.CheckboxField-checkbox {
  color: #b4ccca;
}

.CheckboxField-checkbox.Mui-checked {
  color: #93c99e !important;
}

.CheckboxField-checkbox .MuiSvgIcon-root {
  font-size: 1.8rem;
}

.CheckboxField-label > span {
  color: #edfdf9;
  padding-top: 8.5px;
  padding-bottom: 8.5px;
  font-family: Titillium Web, sans-serif;
  font-size: 1.4rem !important;
  line-height: 1.8rem !important;
}

.CheckboxField-formGroup {
  width: 100%;
  margin-left: 14px;
}

.CheckboxField.roundtop fieldset {
  border-top-left-radius: 1.8rem !important;
  border-top-right-radius: 1.8rem !important;
}

.CheckboxField.roundbottom fieldset {
  border-bottom-right-radius: 1.8rem !important;
  border-bottom-left-radius: 1.8rem !important;
}

.CheckboxField:hover, .CheckboxField:focus {
  border-color: #edfdf9;
}

.ChoiceField {
  color: #edfdf9;
  border-radius: .6rem;
  flex-flow: column;
  margin: 8px 0 4px;
  padding: 8.5px 14px;
  font-family: Titillium Web, sans-serif;
  font-size: 1.4rem;
  line-height: 1.8rem;
  display: flex;
  box-shadow: inset 0 0 30px #edfdf91a;
}

.ChoiceField-input {
  flex-flow: row;
  justify-content: flex-start;
  align-items: center;
  width: 100%;
  display: flex;
}

.ChoiceField-input p {
  color: #e0e0e0;
  font-size: 1.4rem;
  font-weight: 800;
}

.ChoiceField-input button {
  color: #b4ccca;
  background-color: #edfdf91a;
  border: 1px solid #b4ccca;
  border-radius: 2.4rem;
  height: 2em;
  margin: 0 .75ch;
  padding: 0 1em;
  font-size: 1.4rem;
  font-weight: 800;
}

.ChoiceField-input button.selected {
  color: #93c99e;
  border: 1px solid #93c99e;
}

.ChoiceField-input button:hover {
  background-color: #edfdf933;
}

.ChoiceField-content > :first-child {
  margin-top: 1.4rem !important;
}

.ChoiceField.roundtop {
  border-top-left-radius: 1.8rem !important;
  border-top-right-radius: 1.8rem !important;
}

.ChoiceField.roundbottom {
  border-bottom-right-radius: 1.8rem !important;
  border-bottom-left-radius: 1.8rem !important;
}

.FormRadio-button.MuiButtonBase-root, .FormRadio-button.MuiButtonBase-root.Mui-checked {
  color: #edfdf9;
}

.FormRadio-label.MuiFormControlLabel-label {
  color: #edfdf9;
  font-size: 1.8rem;
}

.FileField {
  flex-flow: wrap;
  justify-content: flex-start;
  align-items: center;
  width: 100%;
  display: flex;
}

.FileField-input.MuiTextField-root {
  font-size: 1.8rem;
}

.FileField-input.MuiTextField-root p.Mui-error {
  color: #f6a7b2;
  font-family: Titillium Web, sans-serif;
  font-size: 1.4rem;
  font-weight: 800;
}

.FileField-input.MuiTextField-root p.Mui-error:before {
  content: "Error: ";
}

.FileField-input.MuiTextField-root label {
  font-family: Titillium Web, sans-serif;
  font-weight: 800;
}

.FileField-input.MuiTextField-root label.Mui-focused {
  color: #e0e0e0;
}

.FileField-input.MuiTextField-root label.Mui-error {
  color: #f6a7b2;
}

.FileField-input.MuiTextField-root .MuiOutlinedInput-root .MuiOutlinedInput-input {
  color: #edfdf9;
  cursor: pointer;
  font-family: Roboto Mono, monospace;
  font-size: 1.4rem;
  line-height: 1.8rem;
}

.FileField-input.MuiTextField-root .MuiOutlinedInput-root .MuiSelect-select {
  height: 1.4rem;
}

.FileField-input.MuiTextField-root .MuiOutlinedInput-root textarea {
  scrollbar-width: none;
  -ms-overflow-style: -ms-autohiding-scrollbar;
  white-space: pre;
  overflow-x: scroll !important;
}

.FileField-input.MuiTextField-root .MuiOutlinedInput-root textarea::-webkit-scrollbar {
  display: none;
}

.FileField-input.MuiTextField-root .MuiOutlinedInput-root fieldset {
  border-width: 1px;
  border-color: #a4bab8;
  border-radius: .6rem;
  box-shadow: inset 0 0 30px #edfdf91a;
}

.FileField-input.MuiTextField-root .MuiOutlinedInput-root fieldset legend {
  visibility: visible;
  background-color: #323a3c;
  border-radius: 20px;
  margin-left: -2px;
  padding: 2px;
}

.FileField-input.MuiTextField-root .MuiOutlinedInput-root fieldset legend > span {
  visibility: hidden;
  font-size: .75em;
}

.FileField-input.MuiTextField-root .MuiOutlinedInput-root:hover fieldset, .FileField-input.MuiTextField-root .MuiOutlinedInput-root.Mui-focused fieldset {
  border-color: #edfdf9;
}

.FileField-input.MuiTextField-root .MuiOutlinedInput-root.Mui-error fieldset {
  border-color: #f6a7b2 !important;
}

.FileField-input.MuiTextField-root .MuiOutlinedInput-root.MuiOutlinedInput-root.MuiInputBase-hiddenLabel legend {
  margin-top: 1px;
  padding: 0;
}

.FileField-input.MuiTextField-root .MuiInput-underline:hover:before, .FileField-input.MuiTextField-root .MuiInput-underline:before {
  border-bottom-color: #a4bab8;
}

.FileField-input.MuiTextField-root .MuiInput-underline:hover:after, .FileField-input.MuiTextField-root .MuiInput-underline:after {
  border-bottom-color: #edfdf9;
}

.FileField-input.MuiTextField-root .MuiInput-underline input {
  text-align: center;
  font-family: Titillium Web, sans-serif;
  font-weight: 500;
}

.FileField-input.MuiTextField-root .MuiInput-underline input:active, .FileField-input.MuiTextField-root .MuiInput-underline input:focus {
  text-align: left;
}

.FileField-input.MuiTextField-root :not(.MuiOutlinedInput-input) {
  color: #e0e0e0;
  font-size: 1em;
}

.FileField.roundtop fieldset {
  border-top-left-radius: 1.8rem !important;
  border-top-right-radius: 1.8rem !important;
}

.FileField.roundbottom fieldset {
  border-bottom-right-radius: 1.8rem !important;
  border-bottom-left-radius: 1.8rem !important;
}

.InfoField {
  color: #edfdf9;
  border-radius: .6rem;
  width: 100%;
  margin: 8px 0 4px;
  font-family: Titillium Web, sans-serif;
  font-size: 1.4rem;
  line-height: 1.8rem;
  box-shadow: inset 0 0 30px #edfdf91a;
}

.InfoField-content {
  flex-flow: column;
  padding: 8.5px 14px;
  display: flex;
}

.InfoField-content img, .InfoField-content svg {
  cursor: pointer;
  max-width: 100%;
  max-height: 14.2rem;
  margin: auto;
}

.InfoField.roundtop {
  border-top-left-radius: 1.8rem !important;
  border-top-right-radius: 1.8rem !important;
}

.InfoField.roundbottom {
  border-bottom-right-radius: 1.8rem !important;
  border-bottom-left-radius: 1.8rem !important;
}

.TextField {
  flex-flow: wrap;
  justify-content: flex-start;
  align-items: center;
  width: 100%;
  display: flex;
}

.TextField-input.MuiTextField-root {
  font-size: 1.8rem;
}

.TextField-input.MuiTextField-root p.Mui-error {
  color: #f6a7b2;
  font-family: Titillium Web, sans-serif;
  font-size: 1.4rem;
  font-weight: 800;
}

.TextField-input.MuiTextField-root p.Mui-error:before {
  content: "Error: ";
}

.TextField-input.MuiTextField-root label {
  font-family: Titillium Web, sans-serif;
  font-weight: 800;
}

.TextField-input.MuiTextField-root label.Mui-focused {
  color: #e0e0e0;
}

.TextField-input.MuiTextField-root label.Mui-error {
  color: #f6a7b2;
}

.TextField-input.MuiTextField-root .MuiOutlinedInput-root .MuiOutlinedInput-input {
  color: #edfdf9;
  font-family: Roboto Mono, monospace;
  font-size: 1.4rem;
  line-height: 1.8rem;
}

.TextField-input.MuiTextField-root .MuiOutlinedInput-root .MuiSelect-select {
  height: 1.4rem;
}

.TextField-input.MuiTextField-root .MuiOutlinedInput-root textarea {
  scrollbar-width: none;
  -ms-overflow-style: -ms-autohiding-scrollbar;
  white-space: pre;
  overflow-x: scroll !important;
}

.TextField-input.MuiTextField-root .MuiOutlinedInput-root textarea::-webkit-scrollbar {
  display: none;
}

.TextField-input.MuiTextField-root .MuiOutlinedInput-root fieldset {
  border-width: 1px;
  border-color: #a4bab8;
  border-radius: .6rem;
  box-shadow: inset 0 0 30px #edfdf91a;
}

.TextField-input.MuiTextField-root .MuiOutlinedInput-root fieldset legend {
  visibility: visible;
  background-color: #323a3c;
  border-radius: 20px;
  margin-left: -2px;
  padding: 2px;
}

.TextField-input.MuiTextField-root .MuiOutlinedInput-root fieldset legend > span {
  visibility: hidden;
  font-size: .75em;
}

.TextField-input.MuiTextField-root .MuiOutlinedInput-root:hover fieldset, .TextField-input.MuiTextField-root .MuiOutlinedInput-root.Mui-focused fieldset {
  border-color: #edfdf9;
}

.TextField-input.MuiTextField-root .MuiOutlinedInput-root.Mui-error fieldset {
  border-color: #f6a7b2 !important;
}

.TextField-input.MuiTextField-root .MuiOutlinedInput-root.MuiOutlinedInput-root.MuiInputBase-hiddenLabel legend {
  margin-top: 1px;
  padding: 0;
}

.TextField-input.MuiTextField-root .MuiInput-underline:hover:before, .TextField-input.MuiTextField-root .MuiInput-underline:before {
  border-bottom-color: #a4bab8;
}

.TextField-input.MuiTextField-root .MuiInput-underline:hover:after, .TextField-input.MuiTextField-root .MuiInput-underline:after {
  border-bottom-color: #edfdf9;
}

.TextField-input.MuiTextField-root .MuiInput-underline input {
  text-align: center;
  font-family: Titillium Web, sans-serif;
  font-weight: 500;
}

.TextField-input.MuiTextField-root .MuiInput-underline input:active, .TextField-input.MuiTextField-root .MuiInput-underline input:focus {
  text-align: left;
}

.TextField-input.MuiTextField-root :not(.MuiOutlinedInput-input) {
  color: #e0e0e0;
  font-size: 1em;
}

.TextField.roundtop fieldset {
  border-top-left-radius: 1.8rem !important;
  border-top-right-radius: 1.8rem !important;
}

.TextField.roundbottom fieldset {
  border-bottom-right-radius: 1.8rem !important;
  border-bottom-left-radius: 1.8rem !important;
}

.UnitField {
  flex-flow: row;
  justify-content: center;
  align-items: flex-start;
  gap: 1.4rem;
  width: 100%;
  display: flex;
}

.UnitField-text {
  flex: 2 auto;
}

.UnitField-unit {
  flex: 1 0 25%;
}

.UnitField-unit .MuiTextField-root, .UnitField-unit .MuiInputBase-root {
  width: 100% !important;
}

.UnitField.roundtop .UnitField-text fieldset {
  border-top-left-radius: 1.8rem !important;
}

.UnitField.roundtop .UnitField-unit fieldset {
  border-top-right-radius: 1.8rem !important;
}

.UnitField.roundbottom .UnitField-text fieldset {
  border-bottom-left-radius: 1.8rem !important;
}

.UnitField.roundbottom .UnitField-unit fieldset {
  border-bottom-right-radius: 1.8rem !important;
}

.Button {
  background-color: #b4ccca;
  border-radius: 2.4rem;
  padding: .7rem 2.8rem;
  font-size: 1.8rem;
}

.Button:disabled {
  cursor: not-allowed;
  background-color: #839595;
}

.Button--success {
  background-color: #93c99e;
}

.Button--error {
  background-color: #f6a7b2;
}

.Button-label {
  text-transform: uppercase;
}

.ButtonGroup {
  flex-flow: row;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  display: flex;
}

.ButtonGroup--end {
  margin-top: auto;
}

.ButtonGroup--start {
  margin-bottom: auto;
}

.Tooltip-title {
  font-size: 1.4rem;
}

.Link {
  color: #2fa9dc;
  cursor: pointer;
  text-overflow: ellipsis;
  text-decoration: none;
  display: block;
  overflow-x: hidden;
}

.Link-tooltip {
  background-color: #424c4e;
  padding: 0;
}

.Link-tooltip.MuiTooltip-tooltip {
  background-color: #424c4e;
  padding: 0;
  margin-left: 1.4rem !important;
}

.Link-tooltip-arrow, .Link-tooltip-arrow.MuiTooltip-arrow {
  color: #424c4e;
}

.Link-tooltip-button, .Link-tooltip-button.MuiButtonBase-root {
  border-radius: inherit;
  color: #edfdf9;
  background-color: #323a3c;
  font-size: 1.4rem;
}

.Link-tooltip-button.Mui-disabled, .Link-tooltip-button.MuiButtonBase-root.Mui-disabled {
  color: #edfdf9;
}

.NativeTokenIcon--filled {
  background-color: #fff;
  border-radius: 100%;
  position: initial !important;
}

.GenericTokenIcon--filled {
  position: initial !important;
}

.Placeholder-content {
  text-align: center;
  padding: 2.8rem;
  font-size: 3.45rem;
}

.Placeholder-content--unknownError {
  color: #ed4a61;
}

.Placeholder-content--noData {
  color: #839595;
}

.Placeholder {
  flex: 1;
  justify-content: center;
  align-self: center;
  align-items: center;
  width: 100%;
  height: 100%;
  display: flex;
}

.PopupMenu {
  position: relative;
}

.PopupMenu svg {
  display: block;
}

.PopupMenu:hover .PopupMenu-content {
  visibility: visible !important;
}

.PopupMenu-content {
  visibility: hidden;
  width: -webkit-max-content;
  width: max-content;
  padding-left: 1.4rem;
  position: absolute;
  left: 5.6rem;
}

.PopupMenu-content.align-top {
  top: 0;
}

.PopupMenu-content.align-bottom {
  bottom: 0;
}

.PopupMenu-content ul {
  color: #e0e0e0;
  background-color: #323a3c;
  border-radius: 2.4rem;
  margin-left: 0;
  padding: 0;
  font-size: 1.8rem;
  list-style: none;
  overflow: hidden;
}

.PopupMenu-content ul li {
  cursor: pointer;
  min-width: 100px;
  padding: .7rem 1.4rem;
}

.PopupMenu-content ul li:last-of-type {
  padding-bottom: 1.4rem;
}

.PopupMenu-content ul li:first-of-type {
  padding-top: 1.4rem;
}

.PopupMenu-content ul li:hover {
  background-color: #b4ccca1a;
}

.PopupMenu-content ul li .MuiChip-root {
  background-color: #d8beda;
  height: auto;
  margin-left: 1ch;
  font-size: 1.04rem;
}

.Scrollable {
  scrollbar-width: none;
  -ms-overflow-style: -ms-autohiding-scrollbar;
  padding: inherit;
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  overflow-y: scroll;
}

.Scrollable::-webkit-scrollbar {
  display: none;
}

.IconLabel {
  flex-direction: row;
  align-items: center;
  gap: 1.4rem;
  width: -webkit-fit-content;
  width: -moz-fit-content;
  width: fit-content;
  line-height: 0;
  display: flex;
}

.IconLabel--reversed {
  flex-direction: row-reverse;
}

.IconLabel--symbolic {
  line-height: 1;
}

.IconLabel-icon {
  line-height: inherit;
  border-radius: 50%;
  width: -webkit-fit-content;
  width: -moz-fit-content;
  width: fit-content;
  height: -webkit-fit-content;
  height: -moz-fit-content;
  height: fit-content;
  box-shadow: 0 5px 11px -3px #0000001a;
  cursor: default !important;
}

.IconLabel-icon > img, .IconLabel-icon > svg {
  width: 4.2rem;
  height: 4.2rem;
}

.IconLabel-label {
  font-weight: bold;
  line-height: 0;
}

.NearLink {
  font-family: Roboto Mono, monospace;
}

.Tile {
  background-color: #dcefea;
  border-radius: 2.4rem;
  flex-flow: column;
  gap: 1.4rem;
  padding: 1.4rem;
  font-size: 1.4rem;
  display: flex;
  position: relative;
}

.Tile-header {
  flex-flow: row;
  align-items: center;
  gap: 2.8rem;
  padding: .56rem .56rem 1.4rem;
  display: flex;
}

.Tile-header-text, .Tile-header svg {
  font-size: 2.3rem;
}

.Tile-header-slot--start .MuiButtonBase-root, .Tile-header-slot--end .MuiButtonBase-root {
  padding: 4px;
}

.Tile-header-slot--end {
  margin-left: auto;
}

.Tile-subheader {
  margin-bottom: auto;
}

.Tile-content {
  flex-flow: column;
  width: auto;
  height: 100%;
  display: flex;
  position: relative;
  overflow: hidden;
}

.Tile-footer {
  margin-top: auto;
}

.Tile-subheader, .Tile-footer {
  flex-flow: row;
  justify-content: center;
  display: flex;
}

.DataInspector {
  text-align: start;
}

.DataInspector-label {
  color: #2fa9dc;
  cursor: pointer;
}

.DataInspector-label > span {
  font-family: Titillium Web, sans-serif !important;
}

.DataInspector-body {
  scrollbar-width: none;
  -ms-overflow-style: -ms-autohiding-scrollbar;
  margin-top: 1.4rem;
  overflow-x: scroll;
  overflow-y: hidden;
}

.DataInspector-body::-webkit-scrollbar {
  display: none;
}

.DataInspector-body * {
  font-size: 1.8rem;
  font-family: Roboto Mono, monospace !important;
}

.DataInspector-body div > span {
  cursor: pointer;
}

.DateTimePicker-modal {
  border-radius: 2.4rem !important;
}

.DateTimePicker-modal * {
  font-family: Titillium Web, sans-serif !important;
  font-size: 1.8rem !important;
}

.DateTimePicker-modal .MuiYearPicker-root button {
  padding: 0;
}

.DateTimePicker-modal .MuiClock-pin, .DateTimePicker-modal .MuiClockPointer-root, .DateTimePicker-modal .MuiClockPointer-thumb, .DateTimePicker-modal .MuiPickersDay-root.Mui-selected, .DateTimePicker-modal .PrivatePickersYear-yearButton.Mui-selected {
  border-color: #323a3c;
  background-color: #323a3c !important;
}

.DateTimePicker-input .MuiInputBase-root {
  padding: 0;
}

.DateTimePicker-input .MuiInputAdornment-root {
  position: absolute;
  right: 0;
}

.DateTimePicker-input .MuiInputAdornment-root .MuiIconButton-root {
  margin: 6px;
  padding: 8px;
}

.Dialog .MuiPaper-root {
  color: #e0e0e0;
  background-color: #323a3c;
  border-radius: 2.4rem;
  min-width: 34rem;
  font-size: 1.8rem;
}

.Dialog-title.MuiDialogTitle-root {
  padding: 2.8rem 2.8rem 1.4rem;
  font-family: Titillium Web, sans-serif;
  font-size: 2.3rem;
  font-weight: 800;
}

.Dialog-content {
  flex-flow: column;
  gap: 1.4rem;
  display: flex;
}

.Dialog-content .MuiTextField-root {
  width: 100%;
}

.Dialog-content .MuiTextField-root p.Mui-error {
  color: #f6a7b2;
  font-family: Titillium Web, sans-serif;
  font-size: 1.4rem;
  font-weight: 800;
}

.Dialog-content .MuiTextField-root p.Mui-error:before {
  content: "Error: ";
}

.Dialog-actions.MuiDialogActions-root {
  flex-flow: row;
  justify-content: space-between;
  align-items: center;
  padding: 0 2.8rem 2.8rem;
  display: flex;
}

.TableRow {
  white-space: pre;
  background: none;
  position: relative;
  overflow: hidden;
}

.TableRow .MuiTableCell-root {
  text-align: right;
  border: none;
  font-family: Roboto Mono, monospace;
  font-size: 1.8rem;
}

.TableRow .MuiTableCell-root:first-of-type {
  text-align: left;
  font-family: Titillium Web, sans-serif;
}

.TableRow:hover {
  background-color: #dcefea;
}

.TableRow--compact {
  background-color: #edfdf9;
  border-radius: 2.4rem;
  flex-direction: column;
  gap: 1.4rem;
  padding: 1.4rem;
  display: flex;
}

.TableRow--compact--dense {
  gap: 1.12rem;
  padding: 1.12rem;
}

.TableRow--highlighted--blue {
  border-left: 5.6rem solid #b2dff2;
}

.TableRow--highlighted--green {
  border-left: 5.6rem solid #93c99e;
}

.TableRow--highlighted--red {
  border-left: 5.6rem solid #f6a7b2;
}

.TableRow--highlighted--yellow {
  border-left: 5.6rem solid #ffdf82;
}

.TableRow-content--compact {
  justify-content: space-between;
  align-items: center;
  gap: 2.8rem;
  font-size: 1.4rem;
  display: flex;
}

.TableRow-content--compact:last-of-type {
  border-bottom-right-radius: 2.4rem;
  border-bottom-left-radius: 2.4rem;
}

.TableRow-content--compact:not(.TableRow-content--compact--dense):first-of-type {
  padding: 1.4rem;
}

.TableRow-content--compact:not(.TableRow-content--compact--withTitle) {
  background-color: #dcefeab3;
  padding: 1.4rem 2.8rem;
}

.TableRow-content--compact:not(.TableRow-content--compact--withTitle) :first-of-type {
  border-top-left-radius: 2.4rem;
  border-top-right-radius: 2.4rem;
}

.TableRow-content--compact:not(.TableRow-content--compact--withTitle) > span:first-of-type {
  text-align: start;
}

.TableRow-content--compact .MuiFormControl-root {
  margin-top: 0;
  margin-bottom: 0;
}

.TableRow-content--compact span {
  max-width: 100%;
}

.TableRow-content--compact > span:first-of-type {
  flex: 1;
  font-weight: 800;
}

.TableRow-content--compact > span:not(:first-of-type) {
  text-align: center;
  flex: auto;
}

.TableRow-content--compact > span:not(:first-of-type) .MuiInputBase-root input {
  text-align: center;
}

.TableRow-content--compact > span:last-of-type, .TableRow-content--compact > span:last-of-type .MuiInputBase-root input {
  text-align: end;
}

.TableRow-content--compact--withTitle:first-of-type > span:first-of-type {
  display: none;
}

.TableRow-content--compact--withTitle:first-of-type > span:last-of-type {
  font-size: 1.8rem;
}

.TableRow-content--compact--withTitle:not(:first-of-type) {
  background-color: #dcefeab3;
  padding: 1.4rem 2.8rem;
}

.TableRow-content--compact--withTitle:not(:first-of-type) > span:last-of-type {
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
}

.TableRow-content--compact--withTitle:nth-of-type(2) {
  border-top-left-radius: 2.4rem;
  border-top-right-radius: 2.4rem;
}

.TableRow-content--compact--withTitle--centeredTitle:first-of-type > span:not(:first-of-type) {
  text-align: center;
}

.TableRow-content--compact--dense {
  font-size: 1.8rem;
}

.TableRow-content--compact--dense.TableRow-content--compact--withTitle:not(:first-of-type) {
  padding: 0;
}

.TableRow-content--compact--dense.TableRow-content--compact--withTitle:not(:first-of-type) > span {
  padding: 1.12rem;
}

.TableRow-content--compact--noKeys > span:first-of-type {
  display: none;
}

.TableRow-content--compact--noKeys > span:not(:first-of-type) {
  text-align: center;
  justify-content: center;
  display: flex;
}

.TableRow-content--compact--noKeys > span:not(:first-of-type) .MuiInputBase-root input {
  text-align: center;
}

.TableRow-content--compact .TableRow-content-checkbox {
  display: flex;
}

.TableRow-content--compact .TableRow-content-checkbox .MuiCheckbox-root {
  padding: .45rem;
}

.TableRow-content--compact .TableRow-content-slot {
  align-items: center;
  display: flex;
}

.TableRow-content--compact .TableRow-content-slot--start {
  justify-content: start;
}

.TableRow-content--compact .TableRow-content-slot--end {
  justify-content: end;
}

.TableRow-content--compact .TableRow-content-slot > * {
  position: absolute;
}

.Table {
  border-radius: 2.4rem;
  font-family: Titillium Web, sans-serif;
}

.Table .MuiTableCell-root {
  border-bottom: none;
  padding: 1.4rem;
}

.Table table {
  border-collapse: separate;
  border-spacing: 0;
  width: 100%;
}

.Table-head {
  font-family: Titillium Web, sans-serif;
  overflow: hidden;
}

.Table-head .MuiTableCell-root {
  color: #2a2a2a;
  text-align: right;
  border-bottom: none;
  font-size: 1.8rem;
  font-weight: 800;
}

.Table-head .MuiTableCell-root:first-of-type {
  text-align: left;
}

.Table--compact {
  flex-flow: column;
  gap: 1.68rem;
  display: flex;
}

.Table--dense {
  gap: 1.12rem;
}

.Tabs-item-button {
  color: #2a2a2a;
  background-color: #dcefea;
  height: 100%;
  padding: 0 2.8rem;
  font-size: 1.8rem;
}

.Tabs-item-button.is-active, .Tabs-item-button:hover, .Tabs-item-button:focus {
  transition: background-color .4s, color .4s;
}

.Tabs-item-button.is-active {
  color: #e0e0e0;
}

.Tabs-item-button.is-active, .Tabs-item-button.is-active:hover {
  background-color: #424c4e;
}

.Tabs-item-button:hover {
  background-color: #63717133;
}

.Tabs-item-button--invertedColors {
  color: #e0e0e0;
  background-color: #0000;
}

.Tabs-item-button--invertedColors.is-active {
  color: #2a2a2a;
}

.Tabs-item-button--invertedColors.is-active, .Tabs-item-button--invertedColors.is-active:hover {
  background-color: #dcefea;
}

.Tabs-item-button--invertedColors:hover {
  background-color: #dcefea1a;
}

.Tabs-item-panel {
  width: 100%;
  height: 100%;
  margin: 0 auto;
  display: none;
}

.Tabs-item-panel.is-active {
  justify-content: center;
  align-items: center;
  display: flex;
}

.Tabs-layout-buttonsPanel {
  z-index: 1;
  background-color: #0000;
  border-radius: 2.4rem;
  justify-content: center;
  align-items: center;
  height: 3.92rem;
  padding: 0;
  display: flex;
  overflow: hidden;
  box-shadow: inset 0 0 30px #edfdf91a;
}

.Tabs-layout-buttonsPanel > .Tabs-item-button:first-of-type {
  border-radius: 2.4rem 0 0 2.4rem;
}

.Tabs-layout-buttonsPanel > .Tabs-item-button:last-of-type {
  border-radius: 0 2.4rem 2.4rem 0;
}

.Tabs-layout-contentSpace {
  height: 100%;
}

form.edit {
  scrollbar-width: none;
  -ms-overflow-style: -ms-autohiding-scrollbar;
  flex-flow: column;
  justify-content: flex-start;
  align-items: center;
  width: calc(100% - 5.6rem);
  height: 100%;
  margin: 0;
  padding: 0 2.8rem;
  display: flex;
  position: relative;
  overflow-y: scroll;
}

form.edit::-webkit-scrollbar {
  display: none;
}

form.edit .empty-line {
  width: 100%;
  height: 1em;
}

form.edit .protocol {
  cursor: pointer;
  justify-content: center;
  justify-self: flex-end;
  align-items: center;
  gap: 1ch;
  margin-top: auto;
  padding: 2.8rem;
  font-size: 1.4rem;
  display: flex;
  color: #e0e0e080 !important;
}

form.edit .protocol .logo {
  height: 2.3rem;
  font-size: 2.3rem;
}

form.edit .protocol span {
  white-space: nowrap;
}

form.edit .protocol:hover {
  text-decoration: underline;
  color: #e0e0e0 !important;
}

.MuiMenuItem-root {
  font-size: 1.4rem !important;
}

form.edit {
  scrollbar-width: none;
  -ms-overflow-style: -ms-autohiding-scrollbar;
  flex-flow: column;
  justify-content: flex-start;
  align-items: center;
  width: calc(100% - 5.6rem);
  height: 100%;
  margin: 0;
  padding: 0 2.8rem;
  display: flex;
  position: relative;
  overflow-y: scroll;
}

form.edit::-webkit-scrollbar {
  display: none;
}

form.edit .empty-line {
  width: 100%;
  height: 1em;
}

form.edit .protocol {
  cursor: pointer;
  justify-content: center;
  justify-self: flex-end;
  align-items: center;
  gap: 1ch;
  margin-top: auto;
  padding: 2.8rem;
  font-size: 1.4rem;
  display: flex;
  color: #e0e0e080 !important;
}

form.edit .protocol .logo {
  height: 2.3rem;
  font-size: 2.3rem;
}

form.edit .protocol span {
  white-space: nowrap;
}

form.edit .protocol:hover {
  text-decoration: underline;
  color: #e0e0e0 !important;
}

.MuiMenuItem-root {
  font-size: 1.4rem !important;
}

.custom-task {
  background: #dcefea;
  border-radius: 2.4rem;
  flex-flow: column;
  width: 100%;
  min-height: 14.2rem;
  display: flex;
  position: relative;
  overflow: hidden;
}

.custom-task * {
  color: #2a2a2a;
}

.custom-task .name {
  justify-content: center;
  align-items: center;
  padding-top: .7rem;
  padding-bottom: .7rem;
  font-size: 1.8rem;
  display: flex;
}

.custom-task .name * {
  color: #2a2a2a;
}

.custom-task .name .icon {
  color: #a4bab8;
  fill: #2a2a2a;
  cursor: pointer;
  opacity: 0;
  z-index: 1;
  font-size: 2.3rem;
  transition: opacity .1s;
}

.custom-task .name .icon:hover, .custom-task .name .icon:focus {
  color: #637171;
}

.custom-task .name .icon.edit {
  position: absolute;
  right: 1.4rem;
}

.custom-task .name .icon.edit:hover + .edit-pseudo {
  zoom: 1;
  transform-origin: center;
  transition: all .3s;
  transform: scale(1);
}

.custom-task .name .icon.delete {
  position: absolute;
  left: 1.4rem;
}

.custom-task .name .icon.delete:hover + .delete-pseudo {
  zoom: 1;
  transform-origin: center;
  transition: all .3s;
  transform: scale(1);
}

.custom-task .name .icon.duplicate {
  position: absolute;
  right: 4.2rem;
}

.custom-task .name .icon.duplicate:hover + .duplicate-pseudo {
  zoom: 1;
  transform-origin: center;
  transition: all .3s;
  transform: scale(1);
}

.custom-task .name .delete-pseudo {
  zoom: 0;
  transform-origin: center;
  z-index: 0;
  background-color: #f6a7b2cc;
  border-radius: 100%;
  width: 11.2rem;
  height: 11.2rem;
  position: absolute;
  top: -5.6rem;
  left: -2.8rem;
  transform: scale(0);
}

.custom-task .name .edit-pseudo {
  zoom: 0;
  transform-origin: center;
  z-index: 0;
  background-color: #b2dff2cc;
  border-radius: 100%;
  width: 11.2rem;
  height: 11.2rem;
  position: absolute;
  top: -5.6rem;
  right: -2.8rem;
  transform: scale(0);
}

.custom-task .name .duplicate-pseudo {
  zoom: 0;
  transform-origin: center;
  z-index: 0;
  background-color: #d8bedacc;
  border-radius: 100%;
  width: 11.2rem;
  height: 11.2rem;
  position: absolute;
  top: -5.6rem;
  right: 0;
  transform: scale(0);
}

.custom-task:hover > .name > .icon {
  opacity: 1;
  transition: opacity .1s;
}

.custom-task .data-container {
  scrollbar-width: none;
  -ms-overflow-style: -ms-autohiding-scrollbar;
  z-index: 1;
  background-color: #b4ccca40;
  flex: 1;
  max-height: 100%;
  padding: 1.4rem;
  overflow-y: scroll;
}

.custom-task .data-container::-webkit-scrollbar {
  display: none;
}

.custom-task .data-container .addr {
  flex-flow: row;
  justify-content: space-between;
  width: calc(100% - 2.8rem);
  margin-bottom: -.3rem;
  padding: 0 1.4rem;
  font-size: 1.4rem;
  display: flex;
}

.custom-task .data-container .addr span {
  white-space: nowrap;
}

.custom-task .data-container .addr .code {
  text-overflow: ellipsis;
  white-space: nowrap;
  padding-left: 1.5rem;
  font-family: Roboto Mono, monospace;
  font-size: 1.3rem;
  line-height: 1.85rem;
  overflow: hidden;
}

.custom-task .data-container .action-data-container {
  border: 1px solid #2a2a2a33;
  border-radius: .6rem;
  margin-top: 1.4rem;
  padding: 1.4rem;
}

.custom-task .data-container .action-data-container pre.code {
  scrollbar-width: none;
  -ms-overflow-style: -ms-autohiding-scrollbar;
  color: #2a2a2a;
  background-color: #dcefea;
  border-radius: .6rem;
  margin: .5rem 0;
  padding: .5rem;
  font-family: Roboto Mono, monospace;
  font-size: 1.3rem;
  overflow-x: scroll;
}

.custom-task .data-container .action-data-container pre.code::-webkit-scrollbar {
  display: none;
}

.custom-task .data-container .action-data-container p {
  flex-flow: row;
  justify-content: space-between;
  width: 100%;
  margin-bottom: -.3rem;
  font-size: 1.4rem;
  display: flex;
}

.custom-task .data-container .action-data-container p span {
  white-space: nowrap;
}

.custom-task .data-container .action-data-container p .code {
  text-overflow: ellipsis;
  white-space: nowrap;
  padding-left: 1.5rem;
  font-family: Roboto Mono, monospace;
  font-size: 1.3rem;
  line-height: 1.85rem;
  overflow: hidden;
}

.custom-task .data-container .action-data-container p.expandable {
  flex-wrap: wrap;
}

.custom-task .data-container .action-data-container:first-of-type {
  border-top-left-radius: 1.8rem !important;
  border-top-right-radius: 1.8rem !important;
}

.custom-task .data-container .action-data-container:last-of-type {
  border-bottom-right-radius: 1.8rem !important;
  border-bottom-left-radius: 1.8rem !important;
}

.custom-task.has-errors:not(.is-edited) {
  outline-offset: -4px;
  border-radius: 2.4rem;
  outline: 8px solid #f6a7b2;
}

.custom-task.is-edited {
  outline-offset: -4px;
  border-radius: 2.4rem;
  outline: 8px solid #b2dff2;
}

.custom-task * > a {
  color: #5bbbe3 !important;
}

form.edit {
  scrollbar-width: none;
  -ms-overflow-style: -ms-autohiding-scrollbar;
  flex-flow: column;
  justify-content: flex-start;
  align-items: center;
  width: calc(100% - 5.6rem);
  height: 100%;
  margin: 0;
  padding: 0 2.8rem;
  display: flex;
  position: relative;
  overflow-y: scroll;
}

form.edit::-webkit-scrollbar {
  display: none;
}

form.edit .empty-line {
  width: 100%;
  height: 1em;
}

form.edit .protocol {
  cursor: pointer;
  justify-content: center;
  justify-self: flex-end;
  align-items: center;
  gap: 1ch;
  margin-top: auto;
  padding: 2.8rem;
  font-size: 1.4rem;
  display: flex;
  color: #e0e0e080 !important;
}

form.edit .protocol .logo {
  height: 2.3rem;
  font-size: 2.3rem;
}

form.edit .protocol span {
  white-space: nowrap;
}

form.edit .protocol:hover {
  text-decoration: underline;
  color: #e0e0e0 !important;
}

.MuiMenuItem-root {
  font-size: 1.4rem !important;
}

.multicall-transfer-task {
  background: #424c4e;
  border-radius: 2.4rem;
  flex-flow: column;
  width: 100%;
  min-height: 14.2rem;
  display: flex;
  position: relative;
  overflow: hidden;
}

.multicall-transfer-task * {
  color: #e0e0e0;
}

.multicall-transfer-task .name {
  justify-content: center;
  align-items: center;
  padding-top: .7rem;
  padding-bottom: .7rem;
  font-size: 1.8rem;
  display: flex;
}

.multicall-transfer-task .name * {
  color: #e0e0e0;
}

.multicall-transfer-task .name .icon {
  color: #a4bab8;
  fill: #e0e0e0;
  cursor: pointer;
  opacity: 0;
  z-index: 1;
  font-size: 2.3rem;
  transition: opacity .1s;
}

.multicall-transfer-task .name .icon:hover, .multicall-transfer-task .name .icon:focus {
  color: #637171;
}

.multicall-transfer-task .name .icon.edit {
  position: absolute;
  right: 1.4rem;
}

.multicall-transfer-task .name .icon.edit:hover + .edit-pseudo {
  zoom: 1;
  transform-origin: center;
  transition: all .3s;
  transform: scale(1);
}

.multicall-transfer-task .name .icon.delete {
  position: absolute;
  left: 1.4rem;
}

.multicall-transfer-task .name .icon.delete:hover + .delete-pseudo {
  zoom: 1;
  transform-origin: center;
  transition: all .3s;
  transform: scale(1);
}

.multicall-transfer-task .name .icon.duplicate {
  position: absolute;
  right: 4.2rem;
}

.multicall-transfer-task .name .icon.duplicate:hover + .duplicate-pseudo {
  zoom: 1;
  transform-origin: center;
  transition: all .3s;
  transform: scale(1);
}

.multicall-transfer-task .name .delete-pseudo {
  zoom: 0;
  transform-origin: center;
  z-index: 0;
  background-color: #f6a7b2cc;
  border-radius: 100%;
  width: 11.2rem;
  height: 11.2rem;
  position: absolute;
  top: -5.6rem;
  left: -2.8rem;
  transform: scale(0);
}

.multicall-transfer-task .name .edit-pseudo {
  zoom: 0;
  transform-origin: center;
  z-index: 0;
  background-color: #b2dff2cc;
  border-radius: 100%;
  width: 11.2rem;
  height: 11.2rem;
  position: absolute;
  top: -5.6rem;
  right: -2.8rem;
  transform: scale(0);
}

.multicall-transfer-task .name .duplicate-pseudo {
  zoom: 0;
  transform-origin: center;
  z-index: 0;
  background-color: #d8bedacc;
  border-radius: 100%;
  width: 11.2rem;
  height: 11.2rem;
  position: absolute;
  top: -5.6rem;
  right: 0;
  transform: scale(0);
}

.multicall-transfer-task:hover > .name > .icon {
  opacity: 1;
  transition: opacity .1s;
}

.multicall-transfer-task .data-container {
  scrollbar-width: none;
  -ms-overflow-style: -ms-autohiding-scrollbar;
  z-index: 1;
  background-color: #b4ccca40;
  flex: 1;
  max-height: 100%;
  padding: 1.4rem;
  overflow-y: scroll;
}

.multicall-transfer-task .data-container::-webkit-scrollbar {
  display: none;
}

.multicall-transfer-task .data-container .addr {
  flex-flow: row;
  justify-content: space-between;
  width: calc(100% - 2.8rem);
  margin-bottom: -.3rem;
  padding: 0 1.4rem;
  font-size: 1.4rem;
  display: flex;
}

.multicall-transfer-task .data-container .addr span {
  white-space: nowrap;
}

.multicall-transfer-task .data-container .addr .code {
  text-overflow: ellipsis;
  white-space: nowrap;
  padding-left: 1.5rem;
  font-family: Roboto Mono, monospace;
  font-size: 1.3rem;
  line-height: 1.85rem;
  overflow: hidden;
}

.multicall-transfer-task .data-container .action-data-container {
  border: 1px solid #e0e0e033;
  border-radius: .6rem;
  margin-top: 1.4rem;
  padding: 1.4rem;
}

.multicall-transfer-task .data-container .action-data-container pre.code {
  scrollbar-width: none;
  -ms-overflow-style: -ms-autohiding-scrollbar;
  color: #2a2a2a;
  background-color: #dcefea;
  border-radius: .6rem;
  margin: .5rem 0;
  padding: .5rem;
  font-family: Roboto Mono, monospace;
  font-size: 1.3rem;
  overflow-x: scroll;
}

.multicall-transfer-task .data-container .action-data-container pre.code::-webkit-scrollbar {
  display: none;
}

.multicall-transfer-task .data-container .action-data-container p {
  flex-flow: row;
  justify-content: space-between;
  width: 100%;
  margin-bottom: -.3rem;
  font-size: 1.4rem;
  display: flex;
}

.multicall-transfer-task .data-container .action-data-container p span {
  white-space: nowrap;
}

.multicall-transfer-task .data-container .action-data-container p .code {
  text-overflow: ellipsis;
  white-space: nowrap;
  padding-left: 1.5rem;
  font-family: Roboto Mono, monospace;
  font-size: 1.3rem;
  line-height: 1.85rem;
  overflow: hidden;
}

.multicall-transfer-task .data-container .action-data-container p.expandable {
  flex-wrap: wrap;
}

.multicall-transfer-task .data-container .action-data-container:first-of-type {
  border-top-left-radius: 1.8rem !important;
  border-top-right-radius: 1.8rem !important;
}

.multicall-transfer-task .data-container .action-data-container:last-of-type {
  border-bottom-right-radius: 1.8rem !important;
  border-bottom-left-radius: 1.8rem !important;
}

.multicall-transfer-task.has-errors:not(.is-edited) {
  outline-offset: -4px;
  border-radius: 2.4rem;
  outline: 8px solid #f6a7b2;
}

.multicall-transfer-task.is-edited {
  outline-offset: -4px;
  border-radius: 2.4rem;
  outline: 8px solid #b2dff2;
}

form.edit {
  scrollbar-width: none;
  -ms-overflow-style: -ms-autohiding-scrollbar;
  flex-flow: column;
  justify-content: flex-start;
  align-items: center;
  width: calc(100% - 5.6rem);
  height: 100%;
  margin: 0;
  padding: 0 2.8rem;
  display: flex;
  position: relative;
  overflow-y: scroll;
}

form.edit::-webkit-scrollbar {
  display: none;
}

form.edit .empty-line {
  width: 100%;
  height: 1em;
}

form.edit .protocol {
  cursor: pointer;
  justify-content: center;
  justify-self: flex-end;
  align-items: center;
  gap: 1ch;
  margin-top: auto;
  padding: 2.8rem;
  font-size: 1.4rem;
  display: flex;
  color: #e0e0e080 !important;
}

form.edit .protocol .logo {
  height: 2.3rem;
  font-size: 2.3rem;
}

form.edit .protocol span {
  white-space: nowrap;
}

form.edit .protocol:hover {
  text-decoration: underline;
  color: #e0e0e0 !important;
}

.MuiMenuItem-root {
  font-size: 1.4rem !important;
}

.token-farm-create-token-task {
  background-color: #ffdf82;
  background-image: url("TokenFarm_symbol.b82b4f01.png");
  background-size: 60rem;
  border-radius: 2.4rem;
  flex-flow: column;
  width: 100%;
  min-height: 14.2rem;
  display: flex;
  position: relative;
  overflow: hidden;
}

.token-farm-create-token-task * {
  color: #2a2a2a;
}

.token-farm-create-token-task .name {
  justify-content: center;
  align-items: center;
  padding-top: .7rem;
  padding-bottom: .7rem;
  font-size: 1.8rem;
  display: flex;
}

.token-farm-create-token-task .name * {
  color: #2a2a2a;
}

.token-farm-create-token-task .name .icon {
  color: #a4bab8;
  fill: #2a2a2a;
  cursor: pointer;
  opacity: 0;
  z-index: 1;
  font-size: 2.3rem;
  transition: opacity .1s;
}

.token-farm-create-token-task .name .icon:hover, .token-farm-create-token-task .name .icon:focus {
  color: #637171;
}

.token-farm-create-token-task .name .icon.edit {
  position: absolute;
  right: 1.4rem;
}

.token-farm-create-token-task .name .icon.edit:hover + .edit-pseudo {
  zoom: 1;
  transform-origin: center;
  transition: all .3s;
  transform: scale(1);
}

.token-farm-create-token-task .name .icon.delete {
  position: absolute;
  left: 1.4rem;
}

.token-farm-create-token-task .name .icon.delete:hover + .delete-pseudo {
  zoom: 1;
  transform-origin: center;
  transition: all .3s;
  transform: scale(1);
}

.token-farm-create-token-task .name .icon.duplicate {
  position: absolute;
  right: 4.2rem;
}

.token-farm-create-token-task .name .icon.duplicate:hover + .duplicate-pseudo {
  zoom: 1;
  transform-origin: center;
  transition: all .3s;
  transform: scale(1);
}

.token-farm-create-token-task .name .delete-pseudo {
  zoom: 0;
  transform-origin: center;
  z-index: 0;
  background-color: #f6a7b2cc;
  border-radius: 100%;
  width: 11.2rem;
  height: 11.2rem;
  position: absolute;
  top: -5.6rem;
  left: -2.8rem;
  transform: scale(0);
}

.token-farm-create-token-task .name .edit-pseudo {
  zoom: 0;
  transform-origin: center;
  z-index: 0;
  background-color: #b2dff2cc;
  border-radius: 100%;
  width: 11.2rem;
  height: 11.2rem;
  position: absolute;
  top: -5.6rem;
  right: -2.8rem;
  transform: scale(0);
}

.token-farm-create-token-task .name .duplicate-pseudo {
  zoom: 0;
  transform-origin: center;
  z-index: 0;
  background-color: #d8bedacc;
  border-radius: 100%;
  width: 11.2rem;
  height: 11.2rem;
  position: absolute;
  top: -5.6rem;
  right: 0;
  transform: scale(0);
}

.token-farm-create-token-task:hover > .name > .icon {
  opacity: 1;
  transition: opacity .1s;
}

.token-farm-create-token-task .data-container {
  scrollbar-width: none;
  -ms-overflow-style: -ms-autohiding-scrollbar;
  z-index: 1;
  background-color: #b4ccca40;
  flex: 1;
  max-height: 100%;
  padding: 1.4rem;
  overflow-y: scroll;
}

.token-farm-create-token-task .data-container::-webkit-scrollbar {
  display: none;
}

.token-farm-create-token-task .data-container .addr {
  flex-flow: row;
  justify-content: space-between;
  width: calc(100% - 2.8rem);
  margin-bottom: -.3rem;
  padding: 0 1.4rem;
  font-size: 1.4rem;
  display: flex;
}

.token-farm-create-token-task .data-container .addr span {
  white-space: nowrap;
}

.token-farm-create-token-task .data-container .addr .code {
  text-overflow: ellipsis;
  white-space: nowrap;
  padding-left: 1.5rem;
  font-family: Roboto Mono, monospace;
  font-size: 1.3rem;
  line-height: 1.85rem;
  overflow: hidden;
}

.token-farm-create-token-task .data-container .action-data-container {
  border: 1px solid #2a2a2a33;
  border-radius: .6rem;
  margin-top: 1.4rem;
  padding: 1.4rem;
}

.token-farm-create-token-task .data-container .action-data-container pre.code {
  scrollbar-width: none;
  -ms-overflow-style: -ms-autohiding-scrollbar;
  color: #2a2a2a;
  background-color: #dcefea;
  border-radius: .6rem;
  margin: .5rem 0;
  padding: .5rem;
  font-family: Roboto Mono, monospace;
  font-size: 1.3rem;
  overflow-x: scroll;
}

.token-farm-create-token-task .data-container .action-data-container pre.code::-webkit-scrollbar {
  display: none;
}

.token-farm-create-token-task .data-container .action-data-container p {
  flex-flow: row;
  justify-content: space-between;
  width: 100%;
  margin-bottom: -.3rem;
  font-size: 1.4rem;
  display: flex;
}

.token-farm-create-token-task .data-container .action-data-container p span {
  white-space: nowrap;
}

.token-farm-create-token-task .data-container .action-data-container p .code {
  text-overflow: ellipsis;
  white-space: nowrap;
  padding-left: 1.5rem;
  font-family: Roboto Mono, monospace;
  font-size: 1.3rem;
  line-height: 1.85rem;
  overflow: hidden;
}

.token-farm-create-token-task .data-container .action-data-container p.expandable {
  flex-wrap: wrap;
}

.token-farm-create-token-task .data-container .action-data-container:first-of-type {
  border-top-left-radius: 1.8rem !important;
  border-top-right-radius: 1.8rem !important;
}

.token-farm-create-token-task .data-container .action-data-container:last-of-type {
  border-bottom-right-radius: 1.8rem !important;
  border-bottom-left-radius: 1.8rem !important;
}

.token-farm-create-token-task.has-errors:not(.is-edited) {
  outline-offset: -4px;
  border-radius: 2.4rem;
  outline: 8px solid #f6a7b2;
}

.token-farm-create-token-task.is-edited {
  outline-offset: -4px;
  border-radius: 2.4rem;
  outline: 8px solid #b2dff2;
}

.token-farm-create-token-task * > a {
  color: #2fa9dc !important;
}

form.edit {
  scrollbar-width: none;
  -ms-overflow-style: -ms-autohiding-scrollbar;
  flex-flow: column;
  justify-content: flex-start;
  align-items: center;
  width: calc(100% - 5.6rem);
  height: 100%;
  margin: 0;
  padding: 0 2.8rem;
  display: flex;
  position: relative;
  overflow-y: scroll;
}

form.edit::-webkit-scrollbar {
  display: none;
}

form.edit .empty-line {
  width: 100%;
  height: 1em;
}

form.edit .protocol {
  cursor: pointer;
  justify-content: center;
  justify-self: flex-end;
  align-items: center;
  gap: 1ch;
  margin-top: auto;
  padding: 2.8rem;
  font-size: 1.4rem;
  display: flex;
  color: #e0e0e080 !important;
}

form.edit .protocol .logo {
  height: 2.3rem;
  font-size: 2.3rem;
}

form.edit .protocol span {
  white-space: nowrap;
}

form.edit .protocol:hover {
  text-decoration: underline;
  color: #e0e0e0 !important;
}

.MuiMenuItem-root {
  font-size: 1.4rem !important;
}

.near-storage-withdraw-task, .near-storage-deposit-task, .near-storage-unregister-task, .near-ft-transfer-task, .near-ft-transfer-call-task, .near-nft-transfer-task, .near-nft-transfer-call-task, .near-nft-approve-task, .near-nft-revoke-task, .near-mft-transfer-task, .near-mft-transfer-call-task, .near-deposit-and-stake-task, .near-unstake-task, .near-withdraw-task, .near-wrap-task, .near-unwrap-task {
  background: #dcefea;
  border-radius: 2.4rem;
  flex-flow: column;
  width: 100%;
  min-height: 14.2rem;
  display: flex;
  position: relative;
  overflow: hidden;
}

.near-storage-withdraw-task *, .near-storage-deposit-task *, .near-storage-unregister-task *, .near-ft-transfer-task *, .near-ft-transfer-call-task *, .near-nft-transfer-task *, .near-nft-transfer-call-task *, .near-nft-approve-task *, .near-nft-revoke-task *, .near-mft-transfer-task *, .near-mft-transfer-call-task *, .near-deposit-and-stake-task *, .near-unstake-task *, .near-withdraw-task *, .near-wrap-task *, .near-unwrap-task * {
  color: #2a2a2a;
}

.near-storage-withdraw-task .name, .near-storage-deposit-task .name, .near-storage-unregister-task .name, .near-ft-transfer-task .name, .near-ft-transfer-call-task .name, .near-nft-transfer-task .name, .near-nft-transfer-call-task .name, .near-nft-approve-task .name, .near-nft-revoke-task .name, .near-mft-transfer-task .name, .near-mft-transfer-call-task .name, .near-deposit-and-stake-task .name, .near-unstake-task .name, .near-withdraw-task .name, .near-wrap-task .name, .near-unwrap-task .name {
  justify-content: center;
  align-items: center;
  padding-top: .7rem;
  padding-bottom: .7rem;
  font-size: 1.8rem;
  display: flex;
}

.near-storage-withdraw-task .name *, .near-storage-deposit-task .name *, .near-storage-unregister-task .name *, .near-ft-transfer-task .name *, .near-ft-transfer-call-task .name *, .near-nft-transfer-task .name *, .near-nft-transfer-call-task .name *, .near-nft-approve-task .name *, .near-nft-revoke-task .name *, .near-mft-transfer-task .name *, .near-mft-transfer-call-task .name *, .near-deposit-and-stake-task .name *, .near-unstake-task .name *, .near-withdraw-task .name *, .near-wrap-task .name *, .near-unwrap-task .name * {
  color: #2a2a2a;
}

.near-storage-withdraw-task .name .icon, .near-storage-deposit-task .name .icon, .near-storage-unregister-task .name .icon, .near-ft-transfer-task .name .icon, .near-ft-transfer-call-task .name .icon, .near-nft-transfer-task .name .icon, .near-nft-transfer-call-task .name .icon, .near-nft-approve-task .name .icon, .near-nft-revoke-task .name .icon, .near-mft-transfer-task .name .icon, .near-mft-transfer-call-task .name .icon, .near-deposit-and-stake-task .name .icon, .near-unstake-task .name .icon, .near-withdraw-task .name .icon, .near-wrap-task .name .icon, .near-unwrap-task .name .icon {
  color: #a4bab8;
  fill: #2a2a2a;
  cursor: pointer;
  opacity: 0;
  z-index: 1;
  font-size: 2.3rem;
  transition: opacity .1s;
}

.near-storage-withdraw-task .name .icon:hover, .near-storage-withdraw-task .name .icon:focus, .near-storage-deposit-task .name .icon:hover, .near-storage-deposit-task .name .icon:focus, .near-storage-unregister-task .name .icon:hover, .near-storage-unregister-task .name .icon:focus, .near-ft-transfer-task .name .icon:hover, .near-ft-transfer-task .name .icon:focus, .near-ft-transfer-call-task .name .icon:hover, .near-ft-transfer-call-task .name .icon:focus, .near-nft-transfer-task .name .icon:hover, .near-nft-transfer-task .name .icon:focus, .near-nft-transfer-call-task .name .icon:hover, .near-nft-transfer-call-task .name .icon:focus, .near-nft-approve-task .name .icon:hover, .near-nft-approve-task .name .icon:focus, .near-nft-revoke-task .name .icon:hover, .near-nft-revoke-task .name .icon:focus, .near-mft-transfer-task .name .icon:hover, .near-mft-transfer-task .name .icon:focus, .near-mft-transfer-call-task .name .icon:hover, .near-mft-transfer-call-task .name .icon:focus, .near-deposit-and-stake-task .name .icon:hover, .near-deposit-and-stake-task .name .icon:focus, .near-unstake-task .name .icon:hover, .near-unstake-task .name .icon:focus, .near-withdraw-task .name .icon:hover, .near-withdraw-task .name .icon:focus, .near-wrap-task .name .icon:hover, .near-wrap-task .name .icon:focus, .near-unwrap-task .name .icon:hover, .near-unwrap-task .name .icon:focus {
  color: #637171;
}

.near-storage-withdraw-task .name .icon.edit, .near-storage-deposit-task .name .icon.edit, .near-storage-unregister-task .name .icon.edit, .near-ft-transfer-task .name .icon.edit, .near-ft-transfer-call-task .name .icon.edit, .near-nft-transfer-task .name .icon.edit, .near-nft-transfer-call-task .name .icon.edit, .near-nft-approve-task .name .icon.edit, .near-nft-revoke-task .name .icon.edit, .near-mft-transfer-task .name .icon.edit, .near-mft-transfer-call-task .name .icon.edit, .near-deposit-and-stake-task .name .icon.edit, .near-unstake-task .name .icon.edit, .near-withdraw-task .name .icon.edit, .near-wrap-task .name .icon.edit, .near-unwrap-task .name .icon.edit {
  position: absolute;
  right: 1.4rem;
}

.near-storage-withdraw-task .name .icon.edit:hover + .edit-pseudo, .near-storage-deposit-task .name .icon.edit:hover + .edit-pseudo, .near-storage-unregister-task .name .icon.edit:hover + .edit-pseudo, .near-ft-transfer-task .name .icon.edit:hover + .edit-pseudo, .near-ft-transfer-call-task .name .icon.edit:hover + .edit-pseudo, .near-nft-transfer-task .name .icon.edit:hover + .edit-pseudo, .near-nft-transfer-call-task .name .icon.edit:hover + .edit-pseudo, .near-nft-approve-task .name .icon.edit:hover + .edit-pseudo, .near-nft-revoke-task .name .icon.edit:hover + .edit-pseudo, .near-mft-transfer-task .name .icon.edit:hover + .edit-pseudo, .near-mft-transfer-call-task .name .icon.edit:hover + .edit-pseudo, .near-deposit-and-stake-task .name .icon.edit:hover + .edit-pseudo, .near-unstake-task .name .icon.edit:hover + .edit-pseudo, .near-withdraw-task .name .icon.edit:hover + .edit-pseudo, .near-wrap-task .name .icon.edit:hover + .edit-pseudo, .near-unwrap-task .name .icon.edit:hover + .edit-pseudo {
  zoom: 1;
  transform-origin: center;
  transition: all .3s;
  transform: scale(1);
}

.near-storage-withdraw-task .name .icon.delete, .near-storage-deposit-task .name .icon.delete, .near-storage-unregister-task .name .icon.delete, .near-ft-transfer-task .name .icon.delete, .near-ft-transfer-call-task .name .icon.delete, .near-nft-transfer-task .name .icon.delete, .near-nft-transfer-call-task .name .icon.delete, .near-nft-approve-task .name .icon.delete, .near-nft-revoke-task .name .icon.delete, .near-mft-transfer-task .name .icon.delete, .near-mft-transfer-call-task .name .icon.delete, .near-deposit-and-stake-task .name .icon.delete, .near-unstake-task .name .icon.delete, .near-withdraw-task .name .icon.delete, .near-wrap-task .name .icon.delete, .near-unwrap-task .name .icon.delete {
  position: absolute;
  left: 1.4rem;
}

.near-storage-withdraw-task .name .icon.delete:hover + .delete-pseudo, .near-storage-deposit-task .name .icon.delete:hover + .delete-pseudo, .near-storage-unregister-task .name .icon.delete:hover + .delete-pseudo, .near-ft-transfer-task .name .icon.delete:hover + .delete-pseudo, .near-ft-transfer-call-task .name .icon.delete:hover + .delete-pseudo, .near-nft-transfer-task .name .icon.delete:hover + .delete-pseudo, .near-nft-transfer-call-task .name .icon.delete:hover + .delete-pseudo, .near-nft-approve-task .name .icon.delete:hover + .delete-pseudo, .near-nft-revoke-task .name .icon.delete:hover + .delete-pseudo, .near-mft-transfer-task .name .icon.delete:hover + .delete-pseudo, .near-mft-transfer-call-task .name .icon.delete:hover + .delete-pseudo, .near-deposit-and-stake-task .name .icon.delete:hover + .delete-pseudo, .near-unstake-task .name .icon.delete:hover + .delete-pseudo, .near-withdraw-task .name .icon.delete:hover + .delete-pseudo, .near-wrap-task .name .icon.delete:hover + .delete-pseudo, .near-unwrap-task .name .icon.delete:hover + .delete-pseudo {
  zoom: 1;
  transform-origin: center;
  transition: all .3s;
  transform: scale(1);
}

.near-storage-withdraw-task .name .icon.duplicate, .near-storage-deposit-task .name .icon.duplicate, .near-storage-unregister-task .name .icon.duplicate, .near-ft-transfer-task .name .icon.duplicate, .near-ft-transfer-call-task .name .icon.duplicate, .near-nft-transfer-task .name .icon.duplicate, .near-nft-transfer-call-task .name .icon.duplicate, .near-nft-approve-task .name .icon.duplicate, .near-nft-revoke-task .name .icon.duplicate, .near-mft-transfer-task .name .icon.duplicate, .near-mft-transfer-call-task .name .icon.duplicate, .near-deposit-and-stake-task .name .icon.duplicate, .near-unstake-task .name .icon.duplicate, .near-withdraw-task .name .icon.duplicate, .near-wrap-task .name .icon.duplicate, .near-unwrap-task .name .icon.duplicate {
  position: absolute;
  right: 4.2rem;
}

.near-storage-withdraw-task .name .icon.duplicate:hover + .duplicate-pseudo, .near-storage-deposit-task .name .icon.duplicate:hover + .duplicate-pseudo, .near-storage-unregister-task .name .icon.duplicate:hover + .duplicate-pseudo, .near-ft-transfer-task .name .icon.duplicate:hover + .duplicate-pseudo, .near-ft-transfer-call-task .name .icon.duplicate:hover + .duplicate-pseudo, .near-nft-transfer-task .name .icon.duplicate:hover + .duplicate-pseudo, .near-nft-transfer-call-task .name .icon.duplicate:hover + .duplicate-pseudo, .near-nft-approve-task .name .icon.duplicate:hover + .duplicate-pseudo, .near-nft-revoke-task .name .icon.duplicate:hover + .duplicate-pseudo, .near-mft-transfer-task .name .icon.duplicate:hover + .duplicate-pseudo, .near-mft-transfer-call-task .name .icon.duplicate:hover + .duplicate-pseudo, .near-deposit-and-stake-task .name .icon.duplicate:hover + .duplicate-pseudo, .near-unstake-task .name .icon.duplicate:hover + .duplicate-pseudo, .near-withdraw-task .name .icon.duplicate:hover + .duplicate-pseudo, .near-wrap-task .name .icon.duplicate:hover + .duplicate-pseudo, .near-unwrap-task .name .icon.duplicate:hover + .duplicate-pseudo {
  zoom: 1;
  transform-origin: center;
  transition: all .3s;
  transform: scale(1);
}

.near-storage-withdraw-task .name .delete-pseudo, .near-storage-deposit-task .name .delete-pseudo, .near-storage-unregister-task .name .delete-pseudo, .near-ft-transfer-task .name .delete-pseudo, .near-ft-transfer-call-task .name .delete-pseudo, .near-nft-transfer-task .name .delete-pseudo, .near-nft-transfer-call-task .name .delete-pseudo, .near-nft-approve-task .name .delete-pseudo, .near-nft-revoke-task .name .delete-pseudo, .near-mft-transfer-task .name .delete-pseudo, .near-mft-transfer-call-task .name .delete-pseudo, .near-deposit-and-stake-task .name .delete-pseudo, .near-unstake-task .name .delete-pseudo, .near-withdraw-task .name .delete-pseudo, .near-wrap-task .name .delete-pseudo, .near-unwrap-task .name .delete-pseudo {
  zoom: 0;
  transform-origin: center;
  z-index: 0;
  background-color: #f6a7b2cc;
  border-radius: 100%;
  width: 11.2rem;
  height: 11.2rem;
  position: absolute;
  top: -5.6rem;
  left: -2.8rem;
  transform: scale(0);
}

.near-storage-withdraw-task .name .edit-pseudo, .near-storage-deposit-task .name .edit-pseudo, .near-storage-unregister-task .name .edit-pseudo, .near-ft-transfer-task .name .edit-pseudo, .near-ft-transfer-call-task .name .edit-pseudo, .near-nft-transfer-task .name .edit-pseudo, .near-nft-transfer-call-task .name .edit-pseudo, .near-nft-approve-task .name .edit-pseudo, .near-nft-revoke-task .name .edit-pseudo, .near-mft-transfer-task .name .edit-pseudo, .near-mft-transfer-call-task .name .edit-pseudo, .near-deposit-and-stake-task .name .edit-pseudo, .near-unstake-task .name .edit-pseudo, .near-withdraw-task .name .edit-pseudo, .near-wrap-task .name .edit-pseudo, .near-unwrap-task .name .edit-pseudo {
  zoom: 0;
  transform-origin: center;
  z-index: 0;
  background-color: #b2dff2cc;
  border-radius: 100%;
  width: 11.2rem;
  height: 11.2rem;
  position: absolute;
  top: -5.6rem;
  right: -2.8rem;
  transform: scale(0);
}

.near-storage-withdraw-task .name .duplicate-pseudo, .near-storage-deposit-task .name .duplicate-pseudo, .near-storage-unregister-task .name .duplicate-pseudo, .near-ft-transfer-task .name .duplicate-pseudo, .near-ft-transfer-call-task .name .duplicate-pseudo, .near-nft-transfer-task .name .duplicate-pseudo, .near-nft-transfer-call-task .name .duplicate-pseudo, .near-nft-approve-task .name .duplicate-pseudo, .near-nft-revoke-task .name .duplicate-pseudo, .near-mft-transfer-task .name .duplicate-pseudo, .near-mft-transfer-call-task .name .duplicate-pseudo, .near-deposit-and-stake-task .name .duplicate-pseudo, .near-unstake-task .name .duplicate-pseudo, .near-withdraw-task .name .duplicate-pseudo, .near-wrap-task .name .duplicate-pseudo, .near-unwrap-task .name .duplicate-pseudo {
  zoom: 0;
  transform-origin: center;
  z-index: 0;
  background-color: #d8bedacc;
  border-radius: 100%;
  width: 11.2rem;
  height: 11.2rem;
  position: absolute;
  top: -5.6rem;
  right: 0;
  transform: scale(0);
}

.near-storage-withdraw-task:hover > .name > .icon, .near-storage-deposit-task:hover > .name > .icon, .near-storage-unregister-task:hover > .name > .icon, .near-ft-transfer-task:hover > .name > .icon, .near-ft-transfer-call-task:hover > .name > .icon, .near-nft-transfer-task:hover > .name > .icon, .near-nft-transfer-call-task:hover > .name > .icon, .near-nft-approve-task:hover > .name > .icon, .near-nft-revoke-task:hover > .name > .icon, .near-mft-transfer-task:hover > .name > .icon, .near-mft-transfer-call-task:hover > .name > .icon, .near-deposit-and-stake-task:hover > .name > .icon, .near-unstake-task:hover > .name > .icon, .near-withdraw-task:hover > .name > .icon, .near-wrap-task:hover > .name > .icon, .near-unwrap-task:hover > .name > .icon {
  opacity: 1;
  transition: opacity .1s;
}

.near-storage-withdraw-task .data-container, .near-storage-deposit-task .data-container, .near-storage-unregister-task .data-container, .near-ft-transfer-task .data-container, .near-ft-transfer-call-task .data-container, .near-nft-transfer-task .data-container, .near-nft-transfer-call-task .data-container, .near-nft-approve-task .data-container, .near-nft-revoke-task .data-container, .near-mft-transfer-task .data-container, .near-mft-transfer-call-task .data-container, .near-deposit-and-stake-task .data-container, .near-unstake-task .data-container, .near-withdraw-task .data-container, .near-wrap-task .data-container, .near-unwrap-task .data-container {
  scrollbar-width: none;
  -ms-overflow-style: -ms-autohiding-scrollbar;
  z-index: 1;
  background-color: #b4ccca40;
  flex: 1;
  max-height: 100%;
  padding: 1.4rem;
  overflow-y: scroll;
}

.near-storage-withdraw-task .data-container::-webkit-scrollbar {
  display: none;
}

.near-storage-deposit-task .data-container::-webkit-scrollbar {
  display: none;
}

.near-storage-unregister-task .data-container::-webkit-scrollbar {
  display: none;
}

.near-ft-transfer-task .data-container::-webkit-scrollbar {
  display: none;
}

.near-ft-transfer-call-task .data-container::-webkit-scrollbar {
  display: none;
}

.near-nft-transfer-task .data-container::-webkit-scrollbar {
  display: none;
}

.near-nft-transfer-call-task .data-container::-webkit-scrollbar {
  display: none;
}

.near-nft-approve-task .data-container::-webkit-scrollbar {
  display: none;
}

.near-nft-revoke-task .data-container::-webkit-scrollbar {
  display: none;
}

.near-mft-transfer-task .data-container::-webkit-scrollbar {
  display: none;
}

.near-mft-transfer-call-task .data-container::-webkit-scrollbar {
  display: none;
}

.near-deposit-and-stake-task .data-container::-webkit-scrollbar {
  display: none;
}

.near-unstake-task .data-container::-webkit-scrollbar {
  display: none;
}

.near-withdraw-task .data-container::-webkit-scrollbar {
  display: none;
}

.near-wrap-task .data-container::-webkit-scrollbar {
  display: none;
}

.near-unwrap-task .data-container::-webkit-scrollbar {
  display: none;
}

.near-storage-withdraw-task .data-container .addr, .near-storage-deposit-task .data-container .addr, .near-storage-unregister-task .data-container .addr, .near-ft-transfer-task .data-container .addr, .near-ft-transfer-call-task .data-container .addr, .near-nft-transfer-task .data-container .addr, .near-nft-transfer-call-task .data-container .addr, .near-nft-approve-task .data-container .addr, .near-nft-revoke-task .data-container .addr, .near-mft-transfer-task .data-container .addr, .near-mft-transfer-call-task .data-container .addr, .near-deposit-and-stake-task .data-container .addr, .near-unstake-task .data-container .addr, .near-withdraw-task .data-container .addr, .near-wrap-task .data-container .addr, .near-unwrap-task .data-container .addr {
  flex-flow: row;
  justify-content: space-between;
  width: calc(100% - 2.8rem);
  margin-bottom: -.3rem;
  padding: 0 1.4rem;
  font-size: 1.4rem;
  display: flex;
}

.near-storage-withdraw-task .data-container .addr span, .near-storage-deposit-task .data-container .addr span, .near-storage-unregister-task .data-container .addr span, .near-ft-transfer-task .data-container .addr span, .near-ft-transfer-call-task .data-container .addr span, .near-nft-transfer-task .data-container .addr span, .near-nft-transfer-call-task .data-container .addr span, .near-nft-approve-task .data-container .addr span, .near-nft-revoke-task .data-container .addr span, .near-mft-transfer-task .data-container .addr span, .near-mft-transfer-call-task .data-container .addr span, .near-deposit-and-stake-task .data-container .addr span, .near-unstake-task .data-container .addr span, .near-withdraw-task .data-container .addr span, .near-wrap-task .data-container .addr span, .near-unwrap-task .data-container .addr span {
  white-space: nowrap;
}

.near-storage-withdraw-task .data-container .addr .code, .near-storage-deposit-task .data-container .addr .code, .near-storage-unregister-task .data-container .addr .code, .near-ft-transfer-task .data-container .addr .code, .near-ft-transfer-call-task .data-container .addr .code, .near-nft-transfer-task .data-container .addr .code, .near-nft-transfer-call-task .data-container .addr .code, .near-nft-approve-task .data-container .addr .code, .near-nft-revoke-task .data-container .addr .code, .near-mft-transfer-task .data-container .addr .code, .near-mft-transfer-call-task .data-container .addr .code, .near-deposit-and-stake-task .data-container .addr .code, .near-unstake-task .data-container .addr .code, .near-withdraw-task .data-container .addr .code, .near-wrap-task .data-container .addr .code, .near-unwrap-task .data-container .addr .code {
  text-overflow: ellipsis;
  white-space: nowrap;
  padding-left: 1.5rem;
  font-family: Roboto Mono, monospace;
  font-size: 1.3rem;
  line-height: 1.85rem;
  overflow: hidden;
}

.near-storage-withdraw-task .data-container .action-data-container, .near-storage-deposit-task .data-container .action-data-container, .near-storage-unregister-task .data-container .action-data-container, .near-ft-transfer-task .data-container .action-data-container, .near-ft-transfer-call-task .data-container .action-data-container, .near-nft-transfer-task .data-container .action-data-container, .near-nft-transfer-call-task .data-container .action-data-container, .near-nft-approve-task .data-container .action-data-container, .near-nft-revoke-task .data-container .action-data-container, .near-mft-transfer-task .data-container .action-data-container, .near-mft-transfer-call-task .data-container .action-data-container, .near-deposit-and-stake-task .data-container .action-data-container, .near-unstake-task .data-container .action-data-container, .near-withdraw-task .data-container .action-data-container, .near-wrap-task .data-container .action-data-container, .near-unwrap-task .data-container .action-data-container {
  border: 1px solid #2a2a2a33;
  border-radius: .6rem;
  margin-top: 1.4rem;
  padding: 1.4rem;
}

.near-storage-withdraw-task .data-container .action-data-container pre.code, .near-storage-deposit-task .data-container .action-data-container pre.code, .near-storage-unregister-task .data-container .action-data-container pre.code, .near-ft-transfer-task .data-container .action-data-container pre.code, .near-ft-transfer-call-task .data-container .action-data-container pre.code, .near-nft-transfer-task .data-container .action-data-container pre.code, .near-nft-transfer-call-task .data-container .action-data-container pre.code, .near-nft-approve-task .data-container .action-data-container pre.code, .near-nft-revoke-task .data-container .action-data-container pre.code, .near-mft-transfer-task .data-container .action-data-container pre.code, .near-mft-transfer-call-task .data-container .action-data-container pre.code, .near-deposit-and-stake-task .data-container .action-data-container pre.code, .near-unstake-task .data-container .action-data-container pre.code, .near-withdraw-task .data-container .action-data-container pre.code, .near-wrap-task .data-container .action-data-container pre.code, .near-unwrap-task .data-container .action-data-container pre.code {
  scrollbar-width: none;
  -ms-overflow-style: -ms-autohiding-scrollbar;
  color: #2a2a2a;
  background-color: #dcefea;
  border-radius: .6rem;
  margin: .5rem 0;
  padding: .5rem;
  font-family: Roboto Mono, monospace;
  font-size: 1.3rem;
  overflow-x: scroll;
}

.near-storage-withdraw-task .data-container .action-data-container pre.code::-webkit-scrollbar {
  display: none;
}

.near-storage-deposit-task .data-container .action-data-container pre.code::-webkit-scrollbar {
  display: none;
}

.near-storage-unregister-task .data-container .action-data-container pre.code::-webkit-scrollbar {
  display: none;
}

.near-ft-transfer-task .data-container .action-data-container pre.code::-webkit-scrollbar {
  display: none;
}

.near-ft-transfer-call-task .data-container .action-data-container pre.code::-webkit-scrollbar {
  display: none;
}

.near-nft-transfer-task .data-container .action-data-container pre.code::-webkit-scrollbar {
  display: none;
}

.near-nft-transfer-call-task .data-container .action-data-container pre.code::-webkit-scrollbar {
  display: none;
}

.near-nft-approve-task .data-container .action-data-container pre.code::-webkit-scrollbar {
  display: none;
}

.near-nft-revoke-task .data-container .action-data-container pre.code::-webkit-scrollbar {
  display: none;
}

.near-mft-transfer-task .data-container .action-data-container pre.code::-webkit-scrollbar {
  display: none;
}

.near-mft-transfer-call-task .data-container .action-data-container pre.code::-webkit-scrollbar {
  display: none;
}

.near-deposit-and-stake-task .data-container .action-data-container pre.code::-webkit-scrollbar {
  display: none;
}

.near-unstake-task .data-container .action-data-container pre.code::-webkit-scrollbar {
  display: none;
}

.near-withdraw-task .data-container .action-data-container pre.code::-webkit-scrollbar {
  display: none;
}

.near-wrap-task .data-container .action-data-container pre.code::-webkit-scrollbar {
  display: none;
}

.near-unwrap-task .data-container .action-data-container pre.code::-webkit-scrollbar {
  display: none;
}

.near-storage-withdraw-task .data-container .action-data-container p, .near-storage-deposit-task .data-container .action-data-container p, .near-storage-unregister-task .data-container .action-data-container p, .near-ft-transfer-task .data-container .action-data-container p, .near-ft-transfer-call-task .data-container .action-data-container p, .near-nft-transfer-task .data-container .action-data-container p, .near-nft-transfer-call-task .data-container .action-data-container p, .near-nft-approve-task .data-container .action-data-container p, .near-nft-revoke-task .data-container .action-data-container p, .near-mft-transfer-task .data-container .action-data-container p, .near-mft-transfer-call-task .data-container .action-data-container p, .near-deposit-and-stake-task .data-container .action-data-container p, .near-unstake-task .data-container .action-data-container p, .near-withdraw-task .data-container .action-data-container p, .near-wrap-task .data-container .action-data-container p, .near-unwrap-task .data-container .action-data-container p {
  flex-flow: row;
  justify-content: space-between;
  width: 100%;
  margin-bottom: -.3rem;
  font-size: 1.4rem;
  display: flex;
}

.near-storage-withdraw-task .data-container .action-data-container p span, .near-storage-deposit-task .data-container .action-data-container p span, .near-storage-unregister-task .data-container .action-data-container p span, .near-ft-transfer-task .data-container .action-data-container p span, .near-ft-transfer-call-task .data-container .action-data-container p span, .near-nft-transfer-task .data-container .action-data-container p span, .near-nft-transfer-call-task .data-container .action-data-container p span, .near-nft-approve-task .data-container .action-data-container p span, .near-nft-revoke-task .data-container .action-data-container p span, .near-mft-transfer-task .data-container .action-data-container p span, .near-mft-transfer-call-task .data-container .action-data-container p span, .near-deposit-and-stake-task .data-container .action-data-container p span, .near-unstake-task .data-container .action-data-container p span, .near-withdraw-task .data-container .action-data-container p span, .near-wrap-task .data-container .action-data-container p span, .near-unwrap-task .data-container .action-data-container p span {
  white-space: nowrap;
}

.near-storage-withdraw-task .data-container .action-data-container p .code, .near-storage-deposit-task .data-container .action-data-container p .code, .near-storage-unregister-task .data-container .action-data-container p .code, .near-ft-transfer-task .data-container .action-data-container p .code, .near-ft-transfer-call-task .data-container .action-data-container p .code, .near-nft-transfer-task .data-container .action-data-container p .code, .near-nft-transfer-call-task .data-container .action-data-container p .code, .near-nft-approve-task .data-container .action-data-container p .code, .near-nft-revoke-task .data-container .action-data-container p .code, .near-mft-transfer-task .data-container .action-data-container p .code, .near-mft-transfer-call-task .data-container .action-data-container p .code, .near-deposit-and-stake-task .data-container .action-data-container p .code, .near-unstake-task .data-container .action-data-container p .code, .near-withdraw-task .data-container .action-data-container p .code, .near-wrap-task .data-container .action-data-container p .code, .near-unwrap-task .data-container .action-data-container p .code {
  text-overflow: ellipsis;
  white-space: nowrap;
  padding-left: 1.5rem;
  font-family: Roboto Mono, monospace;
  font-size: 1.3rem;
  line-height: 1.85rem;
  overflow: hidden;
}

.near-storage-withdraw-task .data-container .action-data-container p.expandable, .near-storage-deposit-task .data-container .action-data-container p.expandable, .near-storage-unregister-task .data-container .action-data-container p.expandable, .near-ft-transfer-task .data-container .action-data-container p.expandable, .near-ft-transfer-call-task .data-container .action-data-container p.expandable, .near-nft-transfer-task .data-container .action-data-container p.expandable, .near-nft-transfer-call-task .data-container .action-data-container p.expandable, .near-nft-approve-task .data-container .action-data-container p.expandable, .near-nft-revoke-task .data-container .action-data-container p.expandable, .near-mft-transfer-task .data-container .action-data-container p.expandable, .near-mft-transfer-call-task .data-container .action-data-container p.expandable, .near-deposit-and-stake-task .data-container .action-data-container p.expandable, .near-unstake-task .data-container .action-data-container p.expandable, .near-withdraw-task .data-container .action-data-container p.expandable, .near-wrap-task .data-container .action-data-container p.expandable, .near-unwrap-task .data-container .action-data-container p.expandable {
  flex-wrap: wrap;
}

.near-storage-withdraw-task .data-container .action-data-container:first-of-type, .near-storage-deposit-task .data-container .action-data-container:first-of-type, .near-storage-unregister-task .data-container .action-data-container:first-of-type, .near-ft-transfer-task .data-container .action-data-container:first-of-type, .near-ft-transfer-call-task .data-container .action-data-container:first-of-type, .near-nft-transfer-task .data-container .action-data-container:first-of-type, .near-nft-transfer-call-task .data-container .action-data-container:first-of-type, .near-nft-approve-task .data-container .action-data-container:first-of-type, .near-nft-revoke-task .data-container .action-data-container:first-of-type, .near-mft-transfer-task .data-container .action-data-container:first-of-type, .near-mft-transfer-call-task .data-container .action-data-container:first-of-type, .near-deposit-and-stake-task .data-container .action-data-container:first-of-type, .near-unstake-task .data-container .action-data-container:first-of-type, .near-withdraw-task .data-container .action-data-container:first-of-type, .near-wrap-task .data-container .action-data-container:first-of-type, .near-unwrap-task .data-container .action-data-container:first-of-type {
  border-top-left-radius: 1.8rem !important;
  border-top-right-radius: 1.8rem !important;
}

.near-storage-withdraw-task .data-container .action-data-container:last-of-type, .near-storage-deposit-task .data-container .action-data-container:last-of-type, .near-storage-unregister-task .data-container .action-data-container:last-of-type, .near-ft-transfer-task .data-container .action-data-container:last-of-type, .near-ft-transfer-call-task .data-container .action-data-container:last-of-type, .near-nft-transfer-task .data-container .action-data-container:last-of-type, .near-nft-transfer-call-task .data-container .action-data-container:last-of-type, .near-nft-approve-task .data-container .action-data-container:last-of-type, .near-nft-revoke-task .data-container .action-data-container:last-of-type, .near-mft-transfer-task .data-container .action-data-container:last-of-type, .near-mft-transfer-call-task .data-container .action-data-container:last-of-type, .near-deposit-and-stake-task .data-container .action-data-container:last-of-type, .near-unstake-task .data-container .action-data-container:last-of-type, .near-withdraw-task .data-container .action-data-container:last-of-type, .near-wrap-task .data-container .action-data-container:last-of-type, .near-unwrap-task .data-container .action-data-container:last-of-type {
  border-bottom-right-radius: 1.8rem !important;
  border-bottom-left-radius: 1.8rem !important;
}

.near-storage-withdraw-task.has-errors:not(.is-edited), .near-storage-deposit-task.has-errors:not(.is-edited), .near-storage-unregister-task.has-errors:not(.is-edited), .near-ft-transfer-task.has-errors:not(.is-edited), .near-ft-transfer-call-task.has-errors:not(.is-edited), .near-nft-transfer-task.has-errors:not(.is-edited), .near-nft-transfer-call-task.has-errors:not(.is-edited), .near-nft-approve-task.has-errors:not(.is-edited), .near-nft-revoke-task.has-errors:not(.is-edited), .near-mft-transfer-task.has-errors:not(.is-edited), .near-mft-transfer-call-task.has-errors:not(.is-edited), .near-deposit-and-stake-task.has-errors:not(.is-edited), .near-unstake-task.has-errors:not(.is-edited), .near-withdraw-task.has-errors:not(.is-edited), .near-wrap-task.has-errors:not(.is-edited), .near-unwrap-task.has-errors:not(.is-edited) {
  outline-offset: -4px;
  border-radius: 2.4rem;
  outline: 8px solid #f6a7b2;
}

.near-storage-withdraw-task.is-edited, .near-storage-deposit-task.is-edited, .near-storage-unregister-task.is-edited, .near-ft-transfer-task.is-edited, .near-ft-transfer-call-task.is-edited, .near-nft-transfer-task.is-edited, .near-nft-transfer-call-task.is-edited, .near-nft-approve-task.is-edited, .near-nft-revoke-task.is-edited, .near-mft-transfer-task.is-edited, .near-mft-transfer-call-task.is-edited, .near-deposit-and-stake-task.is-edited, .near-unstake-task.is-edited, .near-withdraw-task.is-edited, .near-wrap-task.is-edited, .near-unwrap-task.is-edited {
  outline-offset: -4px;
  border-radius: 2.4rem;
  outline: 8px solid #b2dff2;
}

.near-storage-withdraw-task * > a, .near-storage-deposit-task * > a, .near-storage-unregister-task * > a, .near-ft-transfer-task * > a, .near-ft-transfer-call-task * > a, .near-nft-transfer-task * > a, .near-nft-transfer-call-task * > a, .near-nft-approve-task * > a, .near-nft-revoke-task * > a, .near-mft-transfer-task * > a, .near-mft-transfer-call-task * > a, .near-deposit-and-stake-task * > a, .near-unstake-task * > a, .near-withdraw-task * > a, .near-wrap-task * > a, .near-unwrap-task * > a {
  color: #5bbbe3 !important;
}

.near-wrap-task-edit .InfoField-content, .near-unwrap-task-edit .InfoField-content {
  flex-flow: column;
  display: flex;
}

.near-wrap-task-edit .InfoField-content .entry, .near-unwrap-task-edit .InfoField-content .entry {
  flex-flow: row;
  justify-content: space-between;
  align-items: center;
  max-width: 100%;
  height: 2em;
  display: flex;
}

.near-wrap-task-edit .InfoField-content .key, .near-unwrap-task-edit .InfoField-content .key {
  white-space: nowrap;
  font-weight: 800;
}

.near-wrap-task-edit .InfoField-content .value, .near-unwrap-task-edit .InfoField-content .value {
  white-space: nowrap;
  text-overflow: ellipsis;
  margin-left: 2em;
  font-family: Roboto Mono, monospace;
  overflow: hidden;
}

.near-nft-approve-task-edit .InfoField-content, .near-nft-revoke-task-edit .InfoField-content {
  flex-flow: column;
  display: flex;
}

.near-nft-approve-task-edit .InfoField-content .approved-account-id, .near-nft-revoke-task-edit .InfoField-content .approved-account-id {
  align-self: flex-end;
}

.MuiInputAdornment-root {
  width: -webkit-fit-content;
  width: -moz-fit-content;
  width: fit-content;
}

.MuiInputAdornment-root p {
  color: #a4bab8;
  font-size: 1.4rem !important;
  display: block !important;
}

form.edit {
  scrollbar-width: none;
  -ms-overflow-style: -ms-autohiding-scrollbar;
  flex-flow: column;
  justify-content: flex-start;
  align-items: center;
  width: calc(100% - 5.6rem);
  height: 100%;
  margin: 0;
  padding: 0 2.8rem;
  display: flex;
  position: relative;
  overflow-y: scroll;
}

form.edit::-webkit-scrollbar {
  display: none;
}

form.edit .empty-line {
  width: 100%;
  height: 1em;
}

form.edit .protocol {
  cursor: pointer;
  justify-content: center;
  justify-self: flex-end;
  align-items: center;
  gap: 1ch;
  margin-top: auto;
  padding: 2.8rem;
  font-size: 1.4rem;
  display: flex;
  color: #e0e0e080 !important;
}

form.edit .protocol .logo {
  height: 2.3rem;
  font-size: 2.3rem;
}

form.edit .protocol span {
  white-space: nowrap;
}

form.edit .protocol:hover {
  text-decoration: underline;
  color: #e0e0e0 !important;
}

.MuiMenuItem-root {
  font-size: 1.4rem !important;
}

.mintbase-create-store-task, .mintbase-transfer-store-ownership-task, .mintbase-add-minter-task, .mintbase-remove-minter-task, .mintbase-buy-nft-task {
  background-color: #070c2b;
  background-image: url("Mintbase_symbol.0816403e.svg");
  background-position: 42rem 239rem;
  background-size: 77rem;
  border-radius: 2.4rem;
  flex-flow: column;
  width: 100%;
  min-height: 14.2rem;
  display: flex;
  position: relative;
  overflow: hidden;
}

.mintbase-create-store-task *, .mintbase-transfer-store-ownership-task *, .mintbase-add-minter-task *, .mintbase-remove-minter-task *, .mintbase-buy-nft-task * {
  color: #e0e0e0;
}

.mintbase-create-store-task .name, .mintbase-transfer-store-ownership-task .name, .mintbase-add-minter-task .name, .mintbase-remove-minter-task .name, .mintbase-buy-nft-task .name {
  justify-content: center;
  align-items: center;
  padding-top: .7rem;
  padding-bottom: .7rem;
  font-size: 1.8rem;
  display: flex;
}

.mintbase-create-store-task .name *, .mintbase-transfer-store-ownership-task .name *, .mintbase-add-minter-task .name *, .mintbase-remove-minter-task .name *, .mintbase-buy-nft-task .name * {
  color: #e0e0e0;
}

.mintbase-create-store-task .name .icon, .mintbase-transfer-store-ownership-task .name .icon, .mintbase-add-minter-task .name .icon, .mintbase-remove-minter-task .name .icon, .mintbase-buy-nft-task .name .icon {
  color: #a4bab8;
  fill: #e0e0e0;
  cursor: pointer;
  opacity: 0;
  z-index: 1;
  font-size: 2.3rem;
  transition: opacity .1s;
}

.mintbase-create-store-task .name .icon:hover, .mintbase-create-store-task .name .icon:focus, .mintbase-transfer-store-ownership-task .name .icon:hover, .mintbase-transfer-store-ownership-task .name .icon:focus, .mintbase-add-minter-task .name .icon:hover, .mintbase-add-minter-task .name .icon:focus, .mintbase-remove-minter-task .name .icon:hover, .mintbase-remove-minter-task .name .icon:focus, .mintbase-buy-nft-task .name .icon:hover, .mintbase-buy-nft-task .name .icon:focus {
  color: #637171;
}

.mintbase-create-store-task .name .icon.edit, .mintbase-transfer-store-ownership-task .name .icon.edit, .mintbase-add-minter-task .name .icon.edit, .mintbase-remove-minter-task .name .icon.edit, .mintbase-buy-nft-task .name .icon.edit {
  position: absolute;
  right: 1.4rem;
}

.mintbase-create-store-task .name .icon.edit:hover + .edit-pseudo, .mintbase-transfer-store-ownership-task .name .icon.edit:hover + .edit-pseudo, .mintbase-add-minter-task .name .icon.edit:hover + .edit-pseudo, .mintbase-remove-minter-task .name .icon.edit:hover + .edit-pseudo, .mintbase-buy-nft-task .name .icon.edit:hover + .edit-pseudo {
  zoom: 1;
  transform-origin: center;
  transition: all .3s;
  transform: scale(1);
}

.mintbase-create-store-task .name .icon.delete, .mintbase-transfer-store-ownership-task .name .icon.delete, .mintbase-add-minter-task .name .icon.delete, .mintbase-remove-minter-task .name .icon.delete, .mintbase-buy-nft-task .name .icon.delete {
  position: absolute;
  left: 1.4rem;
}

.mintbase-create-store-task .name .icon.delete:hover + .delete-pseudo, .mintbase-transfer-store-ownership-task .name .icon.delete:hover + .delete-pseudo, .mintbase-add-minter-task .name .icon.delete:hover + .delete-pseudo, .mintbase-remove-minter-task .name .icon.delete:hover + .delete-pseudo, .mintbase-buy-nft-task .name .icon.delete:hover + .delete-pseudo {
  zoom: 1;
  transform-origin: center;
  transition: all .3s;
  transform: scale(1);
}

.mintbase-create-store-task .name .icon.duplicate, .mintbase-transfer-store-ownership-task .name .icon.duplicate, .mintbase-add-minter-task .name .icon.duplicate, .mintbase-remove-minter-task .name .icon.duplicate, .mintbase-buy-nft-task .name .icon.duplicate {
  position: absolute;
  right: 4.2rem;
}

.mintbase-create-store-task .name .icon.duplicate:hover + .duplicate-pseudo, .mintbase-transfer-store-ownership-task .name .icon.duplicate:hover + .duplicate-pseudo, .mintbase-add-minter-task .name .icon.duplicate:hover + .duplicate-pseudo, .mintbase-remove-minter-task .name .icon.duplicate:hover + .duplicate-pseudo, .mintbase-buy-nft-task .name .icon.duplicate:hover + .duplicate-pseudo {
  zoom: 1;
  transform-origin: center;
  transition: all .3s;
  transform: scale(1);
}

.mintbase-create-store-task .name .delete-pseudo, .mintbase-transfer-store-ownership-task .name .delete-pseudo, .mintbase-add-minter-task .name .delete-pseudo, .mintbase-remove-minter-task .name .delete-pseudo, .mintbase-buy-nft-task .name .delete-pseudo {
  zoom: 0;
  transform-origin: center;
  z-index: 0;
  background-color: #f6a7b2cc;
  border-radius: 100%;
  width: 11.2rem;
  height: 11.2rem;
  position: absolute;
  top: -5.6rem;
  left: -2.8rem;
  transform: scale(0);
}

.mintbase-create-store-task .name .edit-pseudo, .mintbase-transfer-store-ownership-task .name .edit-pseudo, .mintbase-add-minter-task .name .edit-pseudo, .mintbase-remove-minter-task .name .edit-pseudo, .mintbase-buy-nft-task .name .edit-pseudo {
  zoom: 0;
  transform-origin: center;
  z-index: 0;
  background-color: #b2dff2cc;
  border-radius: 100%;
  width: 11.2rem;
  height: 11.2rem;
  position: absolute;
  top: -5.6rem;
  right: -2.8rem;
  transform: scale(0);
}

.mintbase-create-store-task .name .duplicate-pseudo, .mintbase-transfer-store-ownership-task .name .duplicate-pseudo, .mintbase-add-minter-task .name .duplicate-pseudo, .mintbase-remove-minter-task .name .duplicate-pseudo, .mintbase-buy-nft-task .name .duplicate-pseudo {
  zoom: 0;
  transform-origin: center;
  z-index: 0;
  background-color: #d8bedacc;
  border-radius: 100%;
  width: 11.2rem;
  height: 11.2rem;
  position: absolute;
  top: -5.6rem;
  right: 0;
  transform: scale(0);
}

.mintbase-create-store-task:hover > .name > .icon, .mintbase-transfer-store-ownership-task:hover > .name > .icon, .mintbase-add-minter-task:hover > .name > .icon, .mintbase-remove-minter-task:hover > .name > .icon, .mintbase-buy-nft-task:hover > .name > .icon {
  opacity: 1;
  transition: opacity .1s;
}

.mintbase-create-store-task .data-container, .mintbase-transfer-store-ownership-task .data-container, .mintbase-add-minter-task .data-container, .mintbase-remove-minter-task .data-container, .mintbase-buy-nft-task .data-container {
  scrollbar-width: none;
  -ms-overflow-style: -ms-autohiding-scrollbar;
  z-index: 1;
  background-color: #b4ccca40;
  flex: 1;
  max-height: 100%;
  padding: 1.4rem;
  overflow-y: scroll;
}

.mintbase-create-store-task .data-container::-webkit-scrollbar {
  display: none;
}

.mintbase-transfer-store-ownership-task .data-container::-webkit-scrollbar {
  display: none;
}

.mintbase-add-minter-task .data-container::-webkit-scrollbar {
  display: none;
}

.mintbase-remove-minter-task .data-container::-webkit-scrollbar {
  display: none;
}

.mintbase-buy-nft-task .data-container::-webkit-scrollbar {
  display: none;
}

.mintbase-create-store-task .data-container .addr, .mintbase-transfer-store-ownership-task .data-container .addr, .mintbase-add-minter-task .data-container .addr, .mintbase-remove-minter-task .data-container .addr, .mintbase-buy-nft-task .data-container .addr {
  flex-flow: row;
  justify-content: space-between;
  width: calc(100% - 2.8rem);
  margin-bottom: -.3rem;
  padding: 0 1.4rem;
  font-size: 1.4rem;
  display: flex;
}

.mintbase-create-store-task .data-container .addr span, .mintbase-transfer-store-ownership-task .data-container .addr span, .mintbase-add-minter-task .data-container .addr span, .mintbase-remove-minter-task .data-container .addr span, .mintbase-buy-nft-task .data-container .addr span {
  white-space: nowrap;
}

.mintbase-create-store-task .data-container .addr .code, .mintbase-transfer-store-ownership-task .data-container .addr .code, .mintbase-add-minter-task .data-container .addr .code, .mintbase-remove-minter-task .data-container .addr .code, .mintbase-buy-nft-task .data-container .addr .code {
  text-overflow: ellipsis;
  white-space: nowrap;
  padding-left: 1.5rem;
  font-family: Roboto Mono, monospace;
  font-size: 1.3rem;
  line-height: 1.85rem;
  overflow: hidden;
}

.mintbase-create-store-task .data-container .action-data-container, .mintbase-transfer-store-ownership-task .data-container .action-data-container, .mintbase-add-minter-task .data-container .action-data-container, .mintbase-remove-minter-task .data-container .action-data-container, .mintbase-buy-nft-task .data-container .action-data-container {
  border: 1px solid #e0e0e033;
  border-radius: .6rem;
  margin-top: 1.4rem;
  padding: 1.4rem;
}

.mintbase-create-store-task .data-container .action-data-container pre.code, .mintbase-transfer-store-ownership-task .data-container .action-data-container pre.code, .mintbase-add-minter-task .data-container .action-data-container pre.code, .mintbase-remove-minter-task .data-container .action-data-container pre.code, .mintbase-buy-nft-task .data-container .action-data-container pre.code {
  scrollbar-width: none;
  -ms-overflow-style: -ms-autohiding-scrollbar;
  color: #2a2a2a;
  background-color: #dcefea;
  border-radius: .6rem;
  margin: .5rem 0;
  padding: .5rem;
  font-family: Roboto Mono, monospace;
  font-size: 1.3rem;
  overflow-x: scroll;
}

.mintbase-create-store-task .data-container .action-data-container pre.code::-webkit-scrollbar {
  display: none;
}

.mintbase-transfer-store-ownership-task .data-container .action-data-container pre.code::-webkit-scrollbar {
  display: none;
}

.mintbase-add-minter-task .data-container .action-data-container pre.code::-webkit-scrollbar {
  display: none;
}

.mintbase-remove-minter-task .data-container .action-data-container pre.code::-webkit-scrollbar {
  display: none;
}

.mintbase-buy-nft-task .data-container .action-data-container pre.code::-webkit-scrollbar {
  display: none;
}

.mintbase-create-store-task .data-container .action-data-container p, .mintbase-transfer-store-ownership-task .data-container .action-data-container p, .mintbase-add-minter-task .data-container .action-data-container p, .mintbase-remove-minter-task .data-container .action-data-container p, .mintbase-buy-nft-task .data-container .action-data-container p {
  flex-flow: row;
  justify-content: space-between;
  width: 100%;
  margin-bottom: -.3rem;
  font-size: 1.4rem;
  display: flex;
}

.mintbase-create-store-task .data-container .action-data-container p span, .mintbase-transfer-store-ownership-task .data-container .action-data-container p span, .mintbase-add-minter-task .data-container .action-data-container p span, .mintbase-remove-minter-task .data-container .action-data-container p span, .mintbase-buy-nft-task .data-container .action-data-container p span {
  white-space: nowrap;
}

.mintbase-create-store-task .data-container .action-data-container p .code, .mintbase-transfer-store-ownership-task .data-container .action-data-container p .code, .mintbase-add-minter-task .data-container .action-data-container p .code, .mintbase-remove-minter-task .data-container .action-data-container p .code, .mintbase-buy-nft-task .data-container .action-data-container p .code {
  text-overflow: ellipsis;
  white-space: nowrap;
  padding-left: 1.5rem;
  font-family: Roboto Mono, monospace;
  font-size: 1.3rem;
  line-height: 1.85rem;
  overflow: hidden;
}

.mintbase-create-store-task .data-container .action-data-container p.expandable, .mintbase-transfer-store-ownership-task .data-container .action-data-container p.expandable, .mintbase-add-minter-task .data-container .action-data-container p.expandable, .mintbase-remove-minter-task .data-container .action-data-container p.expandable, .mintbase-buy-nft-task .data-container .action-data-container p.expandable {
  flex-wrap: wrap;
}

.mintbase-create-store-task .data-container .action-data-container:first-of-type, .mintbase-transfer-store-ownership-task .data-container .action-data-container:first-of-type, .mintbase-add-minter-task .data-container .action-data-container:first-of-type, .mintbase-remove-minter-task .data-container .action-data-container:first-of-type, .mintbase-buy-nft-task .data-container .action-data-container:first-of-type {
  border-top-left-radius: 1.8rem !important;
  border-top-right-radius: 1.8rem !important;
}

.mintbase-create-store-task .data-container .action-data-container:last-of-type, .mintbase-transfer-store-ownership-task .data-container .action-data-container:last-of-type, .mintbase-add-minter-task .data-container .action-data-container:last-of-type, .mintbase-remove-minter-task .data-container .action-data-container:last-of-type, .mintbase-buy-nft-task .data-container .action-data-container:last-of-type {
  border-bottom-right-radius: 1.8rem !important;
  border-bottom-left-radius: 1.8rem !important;
}

.mintbase-create-store-task.has-errors:not(.is-edited), .mintbase-transfer-store-ownership-task.has-errors:not(.is-edited), .mintbase-add-minter-task.has-errors:not(.is-edited), .mintbase-remove-minter-task.has-errors:not(.is-edited), .mintbase-buy-nft-task.has-errors:not(.is-edited) {
  outline-offset: -4px;
  border-radius: 2.4rem;
  outline: 8px solid #f6a7b2;
}

.mintbase-create-store-task.is-edited, .mintbase-transfer-store-ownership-task.is-edited, .mintbase-add-minter-task.is-edited, .mintbase-remove-minter-task.is-edited, .mintbase-buy-nft-task.is-edited {
  outline-offset: -4px;
  border-radius: 2.4rem;
  outline: 8px solid #b2dff2;
}

.mintbase-add-minter-task-edit .InfoField-content, .mintbase-remove-minter-task-edit .InfoField-content {
  flex-flow: column;
  display: flex;
}

.mintbase-add-minter-task-edit .InfoField-content .minter-account-id, .mintbase-remove-minter-task-edit .InfoField-content .minter-account-id {
  align-self: flex-end;
}

.mintbase-buy-nft-task-edit .InfoField-content {
  flex-flow: column;
  display: flex;
}

.mintbase-buy-nft-task-edit .InfoField-content .entry {
  flex-flow: row;
  justify-content: space-between;
  align-items: center;
  max-width: 100%;
  height: 2em;
  display: flex;
}

.mintbase-buy-nft-task-edit .InfoField-content .key {
  white-space: nowrap;
  font-weight: 800;
}

.mintbase-buy-nft-task-edit .InfoField-content .value {
  white-space: nowrap;
  text-overflow: ellipsis;
  margin-left: 2em;
  font-family: Roboto Mono, monospace;
  overflow: hidden;
}

form.edit {
  scrollbar-width: none;
  -ms-overflow-style: -ms-autohiding-scrollbar;
  flex-flow: column;
  justify-content: flex-start;
  align-items: center;
  width: calc(100% - 5.6rem);
  height: 100%;
  margin: 0;
  padding: 0 2.8rem;
  display: flex;
  position: relative;
  overflow-y: scroll;
}

form.edit::-webkit-scrollbar {
  display: none;
}

form.edit .empty-line {
  width: 100%;
  height: 1em;
}

form.edit .protocol {
  cursor: pointer;
  justify-content: center;
  justify-self: flex-end;
  align-items: center;
  gap: 1ch;
  margin-top: auto;
  padding: 2.8rem;
  font-size: 1.4rem;
  display: flex;
  color: #e0e0e080 !important;
}

form.edit .protocol .logo {
  height: 2.3rem;
  font-size: 2.3rem;
}

form.edit .protocol span {
  white-space: nowrap;
}

form.edit .protocol:hover {
  text-decoration: underline;
  color: #e0e0e0 !important;
}

.MuiMenuItem-root {
  font-size: 1.4rem !important;
}

.paras-buy-nft-task {
  background: radial-gradient(at 0 100%, #1300be 0%, #04002a 100%);
  border-radius: 2.4rem;
  flex-flow: column;
  width: 100%;
  min-height: 14.2rem;
  display: flex;
  position: relative;
  overflow: hidden;
}

.paras-buy-nft-task * {
  color: #e0e0e0;
}

.paras-buy-nft-task .name {
  justify-content: center;
  align-items: center;
  padding-top: .7rem;
  padding-bottom: .7rem;
  font-size: 1.8rem;
  display: flex;
}

.paras-buy-nft-task .name * {
  color: #e0e0e0;
}

.paras-buy-nft-task .name .icon {
  color: #a4bab8;
  fill: #e0e0e0;
  cursor: pointer;
  opacity: 0;
  z-index: 1;
  font-size: 2.3rem;
  transition: opacity .1s;
}

.paras-buy-nft-task .name .icon:hover, .paras-buy-nft-task .name .icon:focus {
  color: #637171;
}

.paras-buy-nft-task .name .icon.edit {
  position: absolute;
  right: 1.4rem;
}

.paras-buy-nft-task .name .icon.edit:hover + .edit-pseudo {
  zoom: 1;
  transform-origin: center;
  transition: all .3s;
  transform: scale(1);
}

.paras-buy-nft-task .name .icon.delete {
  position: absolute;
  left: 1.4rem;
}

.paras-buy-nft-task .name .icon.delete:hover + .delete-pseudo {
  zoom: 1;
  transform-origin: center;
  transition: all .3s;
  transform: scale(1);
}

.paras-buy-nft-task .name .icon.duplicate {
  position: absolute;
  right: 4.2rem;
}

.paras-buy-nft-task .name .icon.duplicate:hover + .duplicate-pseudo {
  zoom: 1;
  transform-origin: center;
  transition: all .3s;
  transform: scale(1);
}

.paras-buy-nft-task .name .delete-pseudo {
  zoom: 0;
  transform-origin: center;
  z-index: 0;
  background-color: #f6a7b2cc;
  border-radius: 100%;
  width: 11.2rem;
  height: 11.2rem;
  position: absolute;
  top: -5.6rem;
  left: -2.8rem;
  transform: scale(0);
}

.paras-buy-nft-task .name .edit-pseudo {
  zoom: 0;
  transform-origin: center;
  z-index: 0;
  background-color: #b2dff2cc;
  border-radius: 100%;
  width: 11.2rem;
  height: 11.2rem;
  position: absolute;
  top: -5.6rem;
  right: -2.8rem;
  transform: scale(0);
}

.paras-buy-nft-task .name .duplicate-pseudo {
  zoom: 0;
  transform-origin: center;
  z-index: 0;
  background-color: #d8bedacc;
  border-radius: 100%;
  width: 11.2rem;
  height: 11.2rem;
  position: absolute;
  top: -5.6rem;
  right: 0;
  transform: scale(0);
}

.paras-buy-nft-task:hover > .name > .icon {
  opacity: 1;
  transition: opacity .1s;
}

.paras-buy-nft-task .data-container {
  scrollbar-width: none;
  -ms-overflow-style: -ms-autohiding-scrollbar;
  z-index: 1;
  background-color: #b4ccca40;
  flex: 1;
  max-height: 100%;
  padding: 1.4rem;
  overflow-y: scroll;
}

.paras-buy-nft-task .data-container::-webkit-scrollbar {
  display: none;
}

.paras-buy-nft-task .data-container .addr {
  flex-flow: row;
  justify-content: space-between;
  width: calc(100% - 2.8rem);
  margin-bottom: -.3rem;
  padding: 0 1.4rem;
  font-size: 1.4rem;
  display: flex;
}

.paras-buy-nft-task .data-container .addr span {
  white-space: nowrap;
}

.paras-buy-nft-task .data-container .addr .code {
  text-overflow: ellipsis;
  white-space: nowrap;
  padding-left: 1.5rem;
  font-family: Roboto Mono, monospace;
  font-size: 1.3rem;
  line-height: 1.85rem;
  overflow: hidden;
}

.paras-buy-nft-task .data-container .action-data-container {
  border: 1px solid #e0e0e033;
  border-radius: .6rem;
  margin-top: 1.4rem;
  padding: 1.4rem;
}

.paras-buy-nft-task .data-container .action-data-container pre.code {
  scrollbar-width: none;
  -ms-overflow-style: -ms-autohiding-scrollbar;
  color: #2a2a2a;
  background-color: #dcefea;
  border-radius: .6rem;
  margin: .5rem 0;
  padding: .5rem;
  font-family: Roboto Mono, monospace;
  font-size: 1.3rem;
  overflow-x: scroll;
}

.paras-buy-nft-task .data-container .action-data-container pre.code::-webkit-scrollbar {
  display: none;
}

.paras-buy-nft-task .data-container .action-data-container p {
  flex-flow: row;
  justify-content: space-between;
  width: 100%;
  margin-bottom: -.3rem;
  font-size: 1.4rem;
  display: flex;
}

.paras-buy-nft-task .data-container .action-data-container p span {
  white-space: nowrap;
}

.paras-buy-nft-task .data-container .action-data-container p .code {
  text-overflow: ellipsis;
  white-space: nowrap;
  padding-left: 1.5rem;
  font-family: Roboto Mono, monospace;
  font-size: 1.3rem;
  line-height: 1.85rem;
  overflow: hidden;
}

.paras-buy-nft-task .data-container .action-data-container p.expandable {
  flex-wrap: wrap;
}

.paras-buy-nft-task .data-container .action-data-container:first-of-type {
  border-top-left-radius: 1.8rem !important;
  border-top-right-radius: 1.8rem !important;
}

.paras-buy-nft-task .data-container .action-data-container:last-of-type {
  border-bottom-right-radius: 1.8rem !important;
  border-bottom-left-radius: 1.8rem !important;
}

.paras-buy-nft-task.has-errors:not(.is-edited) {
  outline-offset: -4px;
  border-radius: 2.4rem;
  outline: 8px solid #f6a7b2;
}

.paras-buy-nft-task.is-edited {
  outline-offset: -4px;
  border-radius: 2.4rem;
  outline: 8px solid #b2dff2;
}

.paras-buy-nft-task-edit .InfoField-content {
  flex-flow: column;
  display: flex;
}

.paras-buy-nft-task-edit .InfoField-content .entry {
  flex-flow: row;
  justify-content: space-between;
  align-items: center;
  max-width: 100%;
  height: 2em;
  display: flex;
}

.paras-buy-nft-task-edit .InfoField-content .key {
  white-space: nowrap;
  font-weight: 800;
}

.paras-buy-nft-task-edit .InfoField-content .value {
  white-space: nowrap;
  text-overflow: ellipsis;
  margin-left: 2em;
  font-family: Roboto Mono, monospace;
  overflow: hidden;
}

form.edit {
  scrollbar-width: none;
  -ms-overflow-style: -ms-autohiding-scrollbar;
  flex-flow: column;
  justify-content: flex-start;
  align-items: center;
  width: calc(100% - 5.6rem);
  height: 100%;
  margin: 0;
  padding: 0 2.8rem;
  display: flex;
  position: relative;
  overflow-y: scroll;
}

form.edit::-webkit-scrollbar {
  display: none;
}

form.edit .empty-line {
  width: 100%;
  height: 1em;
}

form.edit .protocol {
  cursor: pointer;
  justify-content: center;
  justify-self: flex-end;
  align-items: center;
  gap: 1ch;
  margin-top: auto;
  padding: 2.8rem;
  font-size: 1.4rem;
  display: flex;
  color: #e0e0e080 !important;
}

form.edit .protocol .logo {
  height: 2.3rem;
  font-size: 2.3rem;
}

form.edit .protocol span {
  white-space: nowrap;
}

form.edit .protocol:hover {
  text-decoration: underline;
  color: #e0e0e0 !important;
}

.MuiMenuItem-root {
  font-size: 1.4rem !important;
}

.meta-pool-nslp-add-liquidity-task, .meta-pool-nslp-remove-liquidity-task {
  background-color: #735de9;
  background-image: url("MetaPool_symbol.3e4940a8.svg");
  background-position: 0 -2rem;
  background-repeat: no-repeat;
  background-size: 12rem;
  border-radius: 2.4rem;
  flex-flow: column;
  width: 100%;
  min-height: 14.2rem;
  display: flex;
  position: relative;
  overflow: hidden;
}

.meta-pool-nslp-add-liquidity-task *, .meta-pool-nslp-remove-liquidity-task * {
  color: #e0e0e0;
}

.meta-pool-nslp-add-liquidity-task .name, .meta-pool-nslp-remove-liquidity-task .name {
  justify-content: center;
  align-items: center;
  padding-top: .7rem;
  padding-bottom: .7rem;
  font-size: 1.8rem;
  display: flex;
}

.meta-pool-nslp-add-liquidity-task .name *, .meta-pool-nslp-remove-liquidity-task .name * {
  color: #e0e0e0;
}

.meta-pool-nslp-add-liquidity-task .name .icon, .meta-pool-nslp-remove-liquidity-task .name .icon {
  color: #a4bab8;
  fill: #e0e0e0;
  cursor: pointer;
  opacity: 0;
  z-index: 1;
  font-size: 2.3rem;
  transition: opacity .1s;
}

.meta-pool-nslp-add-liquidity-task .name .icon:hover, .meta-pool-nslp-add-liquidity-task .name .icon:focus, .meta-pool-nslp-remove-liquidity-task .name .icon:hover, .meta-pool-nslp-remove-liquidity-task .name .icon:focus {
  color: #637171;
}

.meta-pool-nslp-add-liquidity-task .name .icon.edit, .meta-pool-nslp-remove-liquidity-task .name .icon.edit {
  position: absolute;
  right: 1.4rem;
}

.meta-pool-nslp-add-liquidity-task .name .icon.edit:hover + .edit-pseudo, .meta-pool-nslp-remove-liquidity-task .name .icon.edit:hover + .edit-pseudo {
  zoom: 1;
  transform-origin: center;
  transition: all .3s;
  transform: scale(1);
}

.meta-pool-nslp-add-liquidity-task .name .icon.delete, .meta-pool-nslp-remove-liquidity-task .name .icon.delete {
  position: absolute;
  left: 1.4rem;
}

.meta-pool-nslp-add-liquidity-task .name .icon.delete:hover + .delete-pseudo, .meta-pool-nslp-remove-liquidity-task .name .icon.delete:hover + .delete-pseudo {
  zoom: 1;
  transform-origin: center;
  transition: all .3s;
  transform: scale(1);
}

.meta-pool-nslp-add-liquidity-task .name .icon.duplicate, .meta-pool-nslp-remove-liquidity-task .name .icon.duplicate {
  position: absolute;
  right: 4.2rem;
}

.meta-pool-nslp-add-liquidity-task .name .icon.duplicate:hover + .duplicate-pseudo, .meta-pool-nslp-remove-liquidity-task .name .icon.duplicate:hover + .duplicate-pseudo {
  zoom: 1;
  transform-origin: center;
  transition: all .3s;
  transform: scale(1);
}

.meta-pool-nslp-add-liquidity-task .name .delete-pseudo, .meta-pool-nslp-remove-liquidity-task .name .delete-pseudo {
  zoom: 0;
  transform-origin: center;
  z-index: 0;
  background-color: #f6a7b2cc;
  border-radius: 100%;
  width: 11.2rem;
  height: 11.2rem;
  position: absolute;
  top: -5.6rem;
  left: -2.8rem;
  transform: scale(0);
}

.meta-pool-nslp-add-liquidity-task .name .edit-pseudo, .meta-pool-nslp-remove-liquidity-task .name .edit-pseudo {
  zoom: 0;
  transform-origin: center;
  z-index: 0;
  background-color: #b2dff2cc;
  border-radius: 100%;
  width: 11.2rem;
  height: 11.2rem;
  position: absolute;
  top: -5.6rem;
  right: -2.8rem;
  transform: scale(0);
}

.meta-pool-nslp-add-liquidity-task .name .duplicate-pseudo, .meta-pool-nslp-remove-liquidity-task .name .duplicate-pseudo {
  zoom: 0;
  transform-origin: center;
  z-index: 0;
  background-color: #d8bedacc;
  border-radius: 100%;
  width: 11.2rem;
  height: 11.2rem;
  position: absolute;
  top: -5.6rem;
  right: 0;
  transform: scale(0);
}

.meta-pool-nslp-add-liquidity-task:hover > .name > .icon, .meta-pool-nslp-remove-liquidity-task:hover > .name > .icon {
  opacity: 1;
  transition: opacity .1s;
}

.meta-pool-nslp-add-liquidity-task .data-container, .meta-pool-nslp-remove-liquidity-task .data-container {
  scrollbar-width: none;
  -ms-overflow-style: -ms-autohiding-scrollbar;
  z-index: 1;
  background-color: #b4ccca40;
  flex: 1;
  max-height: 100%;
  padding: 1.4rem;
  overflow-y: scroll;
}

.meta-pool-nslp-add-liquidity-task .data-container::-webkit-scrollbar {
  display: none;
}

.meta-pool-nslp-remove-liquidity-task .data-container::-webkit-scrollbar {
  display: none;
}

.meta-pool-nslp-add-liquidity-task .data-container .addr, .meta-pool-nslp-remove-liquidity-task .data-container .addr {
  flex-flow: row;
  justify-content: space-between;
  width: calc(100% - 2.8rem);
  margin-bottom: -.3rem;
  padding: 0 1.4rem;
  font-size: 1.4rem;
  display: flex;
}

.meta-pool-nslp-add-liquidity-task .data-container .addr span, .meta-pool-nslp-remove-liquidity-task .data-container .addr span {
  white-space: nowrap;
}

.meta-pool-nslp-add-liquidity-task .data-container .addr .code, .meta-pool-nslp-remove-liquidity-task .data-container .addr .code {
  text-overflow: ellipsis;
  white-space: nowrap;
  padding-left: 1.5rem;
  font-family: Roboto Mono, monospace;
  font-size: 1.3rem;
  line-height: 1.85rem;
  overflow: hidden;
}

.meta-pool-nslp-add-liquidity-task .data-container .action-data-container, .meta-pool-nslp-remove-liquidity-task .data-container .action-data-container {
  border: 1px solid #e0e0e033;
  border-radius: .6rem;
  margin-top: 1.4rem;
  padding: 1.4rem;
}

.meta-pool-nslp-add-liquidity-task .data-container .action-data-container pre.code, .meta-pool-nslp-remove-liquidity-task .data-container .action-data-container pre.code {
  scrollbar-width: none;
  -ms-overflow-style: -ms-autohiding-scrollbar;
  color: #2a2a2a;
  background-color: #dcefea;
  border-radius: .6rem;
  margin: .5rem 0;
  padding: .5rem;
  font-family: Roboto Mono, monospace;
  font-size: 1.3rem;
  overflow-x: scroll;
}

.meta-pool-nslp-add-liquidity-task .data-container .action-data-container pre.code::-webkit-scrollbar {
  display: none;
}

.meta-pool-nslp-remove-liquidity-task .data-container .action-data-container pre.code::-webkit-scrollbar {
  display: none;
}

.meta-pool-nslp-add-liquidity-task .data-container .action-data-container p, .meta-pool-nslp-remove-liquidity-task .data-container .action-data-container p {
  flex-flow: row;
  justify-content: space-between;
  width: 100%;
  margin-bottom: -.3rem;
  font-size: 1.4rem;
  display: flex;
}

.meta-pool-nslp-add-liquidity-task .data-container .action-data-container p span, .meta-pool-nslp-remove-liquidity-task .data-container .action-data-container p span {
  white-space: nowrap;
}

.meta-pool-nslp-add-liquidity-task .data-container .action-data-container p .code, .meta-pool-nslp-remove-liquidity-task .data-container .action-data-container p .code {
  text-overflow: ellipsis;
  white-space: nowrap;
  padding-left: 1.5rem;
  font-family: Roboto Mono, monospace;
  font-size: 1.3rem;
  line-height: 1.85rem;
  overflow: hidden;
}

.meta-pool-nslp-add-liquidity-task .data-container .action-data-container p.expandable, .meta-pool-nslp-remove-liquidity-task .data-container .action-data-container p.expandable {
  flex-wrap: wrap;
}

.meta-pool-nslp-add-liquidity-task .data-container .action-data-container:first-of-type, .meta-pool-nslp-remove-liquidity-task .data-container .action-data-container:first-of-type {
  border-top-left-radius: 1.8rem !important;
  border-top-right-radius: 1.8rem !important;
}

.meta-pool-nslp-add-liquidity-task .data-container .action-data-container:last-of-type, .meta-pool-nslp-remove-liquidity-task .data-container .action-data-container:last-of-type {
  border-bottom-right-radius: 1.8rem !important;
  border-bottom-left-radius: 1.8rem !important;
}

.meta-pool-nslp-add-liquidity-task.has-errors:not(.is-edited), .meta-pool-nslp-remove-liquidity-task.has-errors:not(.is-edited) {
  outline-offset: -4px;
  border-radius: 2.4rem;
  outline: 8px solid #f6a7b2;
}

.meta-pool-nslp-add-liquidity-task.is-edited, .meta-pool-nslp-remove-liquidity-task.is-edited {
  outline-offset: -4px;
  border-radius: 2.4rem;
  outline: 8px solid #b2dff2;
}

.meta-pool-nslp-add-liquidity-task-edit .InfoField-content, .meta-pool-nslp-remove-liquidity-task-edit .InfoField-content {
  flex-flow: column;
  display: flex;
}

.meta-pool-nslp-add-liquidity-task-edit .InfoField-content .entry, .meta-pool-nslp-remove-liquidity-task-edit .InfoField-content .entry {
  flex-flow: row;
  justify-content: space-between;
  align-items: center;
  max-width: 100%;
  height: 2em;
  display: flex;
}

.meta-pool-nslp-add-liquidity-task-edit .InfoField-content .key, .meta-pool-nslp-remove-liquidity-task-edit .InfoField-content .key {
  white-space: nowrap;
  font-weight: 800;
}

.meta-pool-nslp-add-liquidity-task-edit .InfoField-content .value, .meta-pool-nslp-remove-liquidity-task-edit .InfoField-content .value {
  white-space: nowrap;
  text-overflow: ellipsis;
  margin-left: 2em;
  font-family: Roboto Mono, monospace;
  overflow: hidden;
}

.task-wrapper {
  border-radius: 2.4rem;
  width: 34rem;
  min-height: 14.2rem;
  margin-bottom: 2.8rem;
  overflow: hidden;
}

.Job-action {
  background-color: #b2dff233;
  border: 2px solid #b2dff2;
  border-radius: 2.4rem;
  align-self: flex-end;
  width: -webkit-fit-content;
  width: -moz-fit-content;
  width: fit-content;
  padding: 0 1em;
  font-size: 1.8rem;
  font-weight: 800;
  color: #2fa9dc !important;
}

.Job-action:hover {
  background-color: #b2dff2;
}

.Job-dataInspector-label {
  width: -webkit-fit-content;
  width: -moz-fit-content;
  width: fit-content;
  margin-left: auto;
}

.Job-multicalls {
  flex-flow: column;
  gap: 1.4rem;
  display: flex;
}

.Job-multicalls-inspector {
  flex-flow: wrap;
  justify-content: space-evenly;
  gap: 1.4rem;
  display: flex;
}

.Job-multicalls-item {
  border: 2px solid #edfdf9;
  border-radius: 2.4rem;
  flex-flow: column;
  gap: 1.4rem;
  min-width: 264px;
  height: -webkit-fit-content;
  height: -moz-fit-content;
  height: fit-content;
  padding: 1.4rem;
  display: flex;
}

.Job-multicalls-item-label {
  position: absolute;
}

.JobEntriesTable {
  grid-area: JobEntriesTable;
}

.JobEntriesTable-body .TableRow-content--compact:not(.JobEntriesTable-body .TableRow-content--compact:first-of-type) span:last-of-type {
  font-family: Roboto Mono, monospace;
}

.JobEntriesTable-body .TableRow-content--compact:last-of-type {
  align-items: flex-start;
}

.JobEntriesTable-body .TableRow-content--compact:last-of-type > span:first-of-type {
  position: absolute;
}

.JobEntriesTable-body .TableRow-content--compact:last-of-type > span:last-of-type {
  width: 100%;
}

.wallet {
  color: #e0e0e0;
  flex-flow: column;
  justify-content: center;
  align-items: center;
  gap: 1.4rem;
  margin-top: 2.8rem;
  margin-bottom: 2.8rem;
  font-size: 1.8rem;
  display: flex;
}

.wallet .icon {
  cursor: pointer;
  border: 1px solid #b4ccca;
  border-radius: 100%;
  width: 3.92rem;
  height: 3.92rem;
  margin-left: -1px;
  font-size: 2.3rem;
  line-height: 3.92rem;
}

.wallet .expand {
  background-color: #323a3c;
  border-radius: 0 2.4rem 2.4rem 0;
  width: -webkit-max-content;
  width: max-content;
  padding: .7rem 1.4rem;
  position: absolute;
  top: calc(-.35rem - 1px);
  left: calc(3.92rem + 2px);
}

.wallet .peek {
  color: #2a2a2a40;
  white-space: nowrap;
  padding: .7rem 2.8rem;
  font-size: 1.8rem;
  position: absolute;
  top: calc(-.35rem - 1px);
  left: calc(3.92rem + 2px);
}

.wallet .user {
  border-radius: 100%;
  width: 3.92rem;
  height: 3.92rem;
  margin-left: -1px;
  font-size: 2.3rem;
  line-height: 3.92rem;
  position: relative;
}

.wallet .user[expand="no"] .expand {
  display: none;
}

.wallet .user[expand="no"] .peek {
  display: block;
}

.wallet .user[expand="yes"] .expand {
  font-size: 1.8rem;
}

.wallet .user[expand="yes"] .expand button {
  width: unset;
  color: #2a2a2a;
  background-color: #b4ccca;
  border-radius: 2.4rem;
  padding: 0 1.4rem;
  font-size: 1.8rem;
}

.wallet .user[expand="yes"] .expand button.logout {
  margin-left: 1ch;
}

.wallet .user[expand="yes"] .peek {
  display: none;
}

.wallet .dao {
  border-radius: 100%;
  width: 3.92rem;
  height: 3.92rem;
  margin-left: -1px;
  font-size: 2.3rem;
  line-height: 3.92rem;
  position: relative;
}

.wallet .dao[expand="no"] .expand {
  display: none;
}

.wallet .dao[expand="no"] .peek {
  display: block;
}

.wallet .dao[expand="yes"] .expand .MuiTextField-root {
  vertical-align: baseline;
  justify-content: center;
  height: 100%;
  font-size: 1.8rem;
}

.wallet .dao[expand="yes"] .expand .MuiTextField-root .MuiOutlinedInput-root {
  height: 100%;
  padding: 0;
}

.wallet .dao[expand="yes"] .expand .MuiTextField-root .MuiOutlinedInput-root input, .wallet .dao[expand="yes"] .expand .MuiTextField-root .MuiOutlinedInput-root textarea {
  color: #e0e0e0;
  width: 25rem;
  height: 100%;
  padding: 0 17.5px 0 0;
  font-family: Titillium Web, sans-serif;
  font-size: 1.8rem;
}

.wallet .dao[expand="yes"] .expand .MuiTextField-root .MuiOutlinedInput-root fieldset {
  border: none;
}

.wallet .dao[expand="yes"] .expand .MuiTextField-root .MuiOutlinedInput-root .MuiAutocomplete-endAdornment {
  top: unset;
  right: 0;
}

.wallet .dao[expand="yes"] .expand .MuiTextField-root .MuiOutlinedInput-root .MuiAutocomplete-endAdornment svg {
  fill: #e0e0e0;
}

.wallet .dao[expand="yes"] .expand .MuiTextField-root :not(input) {
  color: #e0e0e0;
  font-size: 1em;
}

.wallet .dao[expand="yes"] .peek {
  display: none;
}

.wallet .dao.red > .icon {
  color: #f6a7b2;
  border-color: #f6a7b2;
}

.wallet .dao.yellow > .icon {
  color: #ffdf82;
  border-color: #ffdf82;
}

.MuiAutocomplete-popper {
  width: unset !important;
}

.MuiAutocomplete-popper li {
  font-size: 1.8rem;
}

.column-container {
  background-color: #dcefea40;
  border-radius: 2.4rem;
  flex-flow: column;
  flex: 0 0 34rem;
  justify-content: flex-start;
  align-items: center;
  width: 34rem;
  min-height: calc(100% - 5.6rem);
  margin-top: 2.8rem;
  margin-right: 2.8rem;
  padding: 0 2.8rem;
  display: flex;
  position: relative;
}

.column-container .drag-handle {
  color: #a4bab8;
  padding: 1.4rem;
  font-size: 2.3rem;
  line-height: 2.3rem;
  scale: 3 1;
}

.column-container .drag-handle:hover, .column-container .drag-handle:focus {
  color: #637171;
}

.column-container .delete-column {
  color: #a4bab8;
  cursor: pointer;
  font-size: 2.3rem;
  position: absolute;
  top: 1.4rem;
  right: 1.4rem;
}

.column-container .delete-column:hover, .column-container .delete-column:focus {
  color: #637171;
}

.column-container:not(:last-of-type) > .add-column {
  opacity: 0;
}

.column-container:last-of-type > .add-column {
  color: #a4bab8;
  cursor: pointer;
  font-size: 2.3rem;
  transition: opacity .2s ease-in-out;
  position: absolute;
  top: 1.4rem;
  right: -2.8rem;
}

.column-container:last-of-type > .add-column:hover, .column-container:last-of-type > .add-column:focus {
  color: #637171;
}

.column-container .tasks-wrapper {
  flex-flow: column;
  flex: 1;
  justify-content: flex-start;
  align-items: center;
  width: 100%;
  display: flex;
}

.light-textfield.MuiTextField-root {
  font-size: 1.8rem;
}

.light-textfield.MuiTextField-root .MuiInputLabel-root:not(.Mui-error), .light-textfield.MuiTextField-root .MuiFilledInput-root.Mui-focused:not(.Mui-error) {
  color: #e0e0e0 !important;
}

.light-textfield.MuiTextField-root .MuiInputLabel-root.Mui-error, .light-textfield.MuiTextField-root .MuiFilledInput-root.Mui-focused.Mui-error {
  color: #f6a7b2 !important;
}

.light-textfield.MuiTextField-root .MuiFilledInput-root, .light-textfield.MuiTextField-root .MuiFilledInput-root:hover {
  background-color: #edfdf90f !important;
}

.light-textfield.MuiTextField-root .MuiFilledInput-root:before, .light-textfield.MuiTextField-root .MuiFilledInput-root:hover:before, .light-textfield.MuiTextField-root .MuiFilledInput-root:hover:before, .light-textfield.MuiTextField-root .MuiFilledInput-root:hover:hover:before {
  border-bottom: 1px solid #edfdf96b;
}

.light-textfield.MuiTextField-root .MuiFilledInput-root:after, .light-textfield.MuiTextField-root .MuiFilledInput-root:hover:after {
  border-bottom: 2px solid #edfdf9;
}

.light-textfield.MuiTextField-root .MuiFilledInput-root.Mui-error:before, .light-textfield.MuiTextField-root .MuiFilledInput-root.Mui-error:hover:before, .light-textfield.MuiTextField-root .MuiFilledInput-root:hover.Mui-error:before, .light-textfield.MuiTextField-root .MuiFilledInput-root:hover.Mui-error:hover:before {
  border-bottom: 1px solid #f6a7b26b !important;
}

.light-textfield.MuiTextField-root .MuiFilledInput-root.Mui-error:after, .light-textfield.MuiTextField-root .MuiFilledInput-root:hover.Mui-error:after {
  border-bottom: 2px solid #f6a7b2 !important;
}

.light-textfield.MuiTextField-root .MuiFilledInput-root input, .light-textfield.MuiTextField-root .MuiFilledInput-root textarea, .light-textfield.MuiTextField-root .MuiFilledInput-root:hover input, .light-textfield.MuiTextField-root .MuiFilledInput-root:hover textarea {
  color: #edfdf9;
  font-size: 1.8rem;
}

.light-textfield.MuiTextField-root .MuiFilledInput-root textarea, .light-textfield.MuiTextField-root .MuiFilledInput-root:hover textarea {
  scrollbar-width: none;
  -ms-overflow-style: -ms-autohiding-scrollbar;
  white-space: pre;
  overflow-x: scroll !important;
}

.light-textfield.MuiTextField-root .MuiFilledInput-root textarea::-webkit-scrollbar {
  display: none;
}

.light-textfield.MuiTextField-root .MuiFilledInput-root:hover textarea::-webkit-scrollbar {
  display: none;
}

.light-textfield.MuiTextField-root :not(input) {
  color: #e0e0e0;
  font-size: 1em;
}

.spacer {
  flex: 1;
}

.loader {
  flex: 1;
  justify-content: center;
  align-self: center;
  align-items: center;
  width: 100%;
  height: 100%;
  display: flex;
}

.loader:after {
  content: " ";
  border: 6px solid #a4bab8;
  border-color: #a4bab8 #0000;
  border-radius: 100%;
  width: 64px;
  height: 64px;
  animation: 1.2s linear infinite lds-dual-ring;
  display: block;
}

@keyframes lds-dual-ring {
  0% {
    transform: rotate(0);
  }

  100% {
    transform: rotate(360deg);
  }
}

.Builder {
  justify-content: flex-start;
  align-items: center;
  gap: 2.8rem;
  display: flex;
}

.Builder-form {
  width: 100%;
}

.Builder-droppable {
  flex: 1;
  width: 100%;
  position: relative;
}

.Builder-selector {
  scrollbar-width: none;
  -ms-overflow-style: -ms-autohiding-scrollbar;
  z-index: 2;
  border-radius: 2.4rem;
  width: 34rem;
  max-height: 100%;
  position: absolute;
  left: 0;
  overflow-y: scroll;
}

.Builder-selector::-webkit-scrollbar {
  display: none;
}

.Builder-selector .column-container {
  width: 100%;
  height: 100%;
  background-color: #0000 !important;
  margin: 0 !important;
  padding: 0 !important;
}

.Builder-selector .column-container .tasks-wrapper {
  flex: 1 1 0;
  box-shadow: inset 0 0 30px #edfdf91a;
}

.Builder-selector .column-container .tasks-wrapper .task-wrapper:last-of-type {
  margin-bottom: 0 !important;
}

.Builder-selector .column-container .tasks-wrapper .icon {
  display: none;
}

.Builder-selector .column-container .tasks-wrapper .task-container {
  outline: none !important;
}

.light-textfield.MuiTextField-root {
  font-size: 1.8rem;
}

.light-textfield.MuiTextField-root .MuiInputLabel-root:not(.Mui-error), .light-textfield.MuiTextField-root .MuiFilledInput-root.Mui-focused:not(.Mui-error) {
  color: #e0e0e0 !important;
}

.light-textfield.MuiTextField-root .MuiInputLabel-root.Mui-error, .light-textfield.MuiTextField-root .MuiFilledInput-root.Mui-focused.Mui-error {
  color: #f6a7b2 !important;
}

.light-textfield.MuiTextField-root .MuiFilledInput-root, .light-textfield.MuiTextField-root .MuiFilledInput-root:hover {
  background-color: #edfdf90f !important;
}

.light-textfield.MuiTextField-root .MuiFilledInput-root:before, .light-textfield.MuiTextField-root .MuiFilledInput-root:hover:before, .light-textfield.MuiTextField-root .MuiFilledInput-root:hover:before, .light-textfield.MuiTextField-root .MuiFilledInput-root:hover:hover:before {
  border-bottom: 1px solid #edfdf96b;
}

.light-textfield.MuiTextField-root .MuiFilledInput-root:after, .light-textfield.MuiTextField-root .MuiFilledInput-root:hover:after {
  border-bottom: 2px solid #edfdf9;
}

.light-textfield.MuiTextField-root .MuiFilledInput-root.Mui-error:before, .light-textfield.MuiTextField-root .MuiFilledInput-root.Mui-error:hover:before, .light-textfield.MuiTextField-root .MuiFilledInput-root:hover.Mui-error:before, .light-textfield.MuiTextField-root .MuiFilledInput-root:hover.Mui-error:hover:before {
  border-bottom: 1px solid #f6a7b26b !important;
}

.light-textfield.MuiTextField-root .MuiFilledInput-root.Mui-error:after, .light-textfield.MuiTextField-root .MuiFilledInput-root:hover.Mui-error:after {
  border-bottom: 2px solid #f6a7b2 !important;
}

.light-textfield.MuiTextField-root .MuiFilledInput-root input, .light-textfield.MuiTextField-root .MuiFilledInput-root textarea, .light-textfield.MuiTextField-root .MuiFilledInput-root:hover input, .light-textfield.MuiTextField-root .MuiFilledInput-root:hover textarea {
  color: #edfdf9;
  font-size: 1.8rem;
}

.light-textfield.MuiTextField-root .MuiFilledInput-root textarea, .light-textfield.MuiTextField-root .MuiFilledInput-root:hover textarea {
  scrollbar-width: none;
  -ms-overflow-style: -ms-autohiding-scrollbar;
  white-space: pre;
  overflow-x: scroll !important;
}

.light-textfield.MuiTextField-root .MuiFilledInput-root textarea::-webkit-scrollbar {
  display: none;
}

.light-textfield.MuiTextField-root .MuiFilledInput-root:hover textarea::-webkit-scrollbar {
  display: none;
}

.light-textfield.MuiTextField-root :not(input) {
  color: #e0e0e0;
  font-size: 1em;
}

.spacer {
  flex: 1;
}

.loader {
  flex: 1;
  justify-content: center;
  align-self: center;
  align-items: center;
  width: 100%;
  height: 100%;
  display: flex;
}

.loader:after {
  content: " ";
  border: 6px solid #a4bab8;
  border-color: #a4bab8 #0000;
  border-radius: 100%;
  width: 64px;
  height: 64px;
  animation: 1.2s linear infinite lds-dual-ring;
  display: block;
}

@keyframes lds-dual-ring {
  0% {
    transform: rotate(0);
  }

  100% {
    transform: rotate(360deg);
  }
}

.placeholder {
  color: #e0e0e0;
  text-align: center;
  justify-content: center;
  align-items: center;
  padding: 0 2.8rem;
  font-size: 1.8rem;
  display: flex;
}

.placeholder .huge-icon {
  margin-top: -5.6rem;
  margin-bottom: 2.8rem;
  font-size: 19.2rem;
}

.placeholder h3 > .icon {
  font-size: 2.3rem;
}

.light-textfield.MuiTextField-root {
  font-size: 1.8rem;
}

.light-textfield.MuiTextField-root .MuiInputLabel-root:not(.Mui-error), .light-textfield.MuiTextField-root .MuiFilledInput-root.Mui-focused:not(.Mui-error) {
  color: #e0e0e0 !important;
}

.light-textfield.MuiTextField-root .MuiInputLabel-root.Mui-error, .light-textfield.MuiTextField-root .MuiFilledInput-root.Mui-focused.Mui-error {
  color: #f6a7b2 !important;
}

.light-textfield.MuiTextField-root .MuiFilledInput-root, .light-textfield.MuiTextField-root .MuiFilledInput-root:hover {
  background-color: #edfdf90f !important;
}

.light-textfield.MuiTextField-root .MuiFilledInput-root:before, .light-textfield.MuiTextField-root .MuiFilledInput-root:hover:before, .light-textfield.MuiTextField-root .MuiFilledInput-root:hover:before, .light-textfield.MuiTextField-root .MuiFilledInput-root:hover:hover:before {
  border-bottom: 1px solid #edfdf96b;
}

.light-textfield.MuiTextField-root .MuiFilledInput-root:after, .light-textfield.MuiTextField-root .MuiFilledInput-root:hover:after {
  border-bottom: 2px solid #edfdf9;
}

.light-textfield.MuiTextField-root .MuiFilledInput-root.Mui-error:before, .light-textfield.MuiTextField-root .MuiFilledInput-root.Mui-error:hover:before, .light-textfield.MuiTextField-root .MuiFilledInput-root:hover.Mui-error:before, .light-textfield.MuiTextField-root .MuiFilledInput-root:hover.Mui-error:hover:before {
  border-bottom: 1px solid #f6a7b26b !important;
}

.light-textfield.MuiTextField-root .MuiFilledInput-root.Mui-error:after, .light-textfield.MuiTextField-root .MuiFilledInput-root:hover.Mui-error:after {
  border-bottom: 2px solid #f6a7b2 !important;
}

.light-textfield.MuiTextField-root .MuiFilledInput-root input, .light-textfield.MuiTextField-root .MuiFilledInput-root textarea, .light-textfield.MuiTextField-root .MuiFilledInput-root:hover input, .light-textfield.MuiTextField-root .MuiFilledInput-root:hover textarea {
  color: #edfdf9;
  font-size: 1.8rem;
}

.light-textfield.MuiTextField-root .MuiFilledInput-root textarea, .light-textfield.MuiTextField-root .MuiFilledInput-root:hover textarea {
  scrollbar-width: none;
  -ms-overflow-style: -ms-autohiding-scrollbar;
  white-space: pre;
  overflow-x: scroll !important;
}

.light-textfield.MuiTextField-root .MuiFilledInput-root textarea::-webkit-scrollbar {
  display: none;
}

.light-textfield.MuiTextField-root .MuiFilledInput-root:hover textarea::-webkit-scrollbar {
  display: none;
}

.light-textfield.MuiTextField-root :not(input) {
  color: #e0e0e0;
  font-size: 1em;
}

.spacer {
  flex: 1;
}

.loader {
  flex: 1;
  justify-content: center;
  align-self: center;
  align-items: center;
  width: 100%;
  height: 100%;
  display: flex;
}

.loader:after {
  content: " ";
  border: 6px solid #a4bab8;
  border-color: #a4bab8 #0000;
  border-radius: 100%;
  width: 64px;
  height: 64px;
  animation: 1.2s linear infinite lds-dual-ring;
  display: block;
}

@keyframes lds-dual-ring {
  0% {
    transform: rotate(0);
  }

  100% {
    transform: rotate(360deg);
  }
}

.Export {
  scrollbar-width: none;
  -ms-overflow-style: -ms-autohiding-scrollbar;
  flex-flow: column;
  gap: 2.8rem;
  width: 100%;
  height: 100%;
  margin: 0;
  padding: 0;
  display: flex;
  position: relative;
  overflow-y: scroll;
}

.Export::-webkit-scrollbar {
  display: none;
}

.Export-scheduler-modeSelector.MuiFormControl-root {
  width: 100%;
}

.Export-action {
  z-index: 1;
  border-radius: 2.4rem;
  margin-top: auto;
  padding: .7rem 1.4rem;
  position: -webkit-sticky;
  position: sticky;
  bottom: 0;
}

.Export-action a {
  color: #b2dff2;
}

.Export-action--propose {
  background-color: #93c99e;
}

.Export-action--propose:disabled {
  cursor: not-allowed;
  background-color: #839595;
}

.Export-action--propose p {
  color: #f6a7b2;
  font-size: 1.3rem;
}

.Export-action--login {
  background-color: #b4ccca;
}

.Export-section {
  flex-flow: column;
  flex: 1;
  justify-content: flex-start;
  align-items: center;
  width: 100%;
  max-height: 25%;
  display: flex;
  position: relative;
}

.Export-section .header {
  justify-content: space-between;
  align-items: center;
  width: calc(100% - 4.2rem);
  display: flex;
}

.Export-section .header h3 {
  cursor: pointer;
  color: #e0e0e0;
  text-align: left;
  font-size: 1.8rem;
}

.Export-section .header .icon {
  color: #a4bab8;
  cursor: pointer;
  font-size: 1.8rem;
}

.Export-section .header .icon:hover, .Export-section .header .icon:focus {
  color: #dcefea;
}

.Export-section .header .collapse {
  transition: all 50ms linear;
  position: absolute;
  left: 0;
}

.Export-section .header .collapse[collapsed="yes"] {
  transform: rotate(-90deg);
}

.Export-section .value {
  scrollbar-width: none;
  -ms-overflow-style: -ms-autohiding-scrollbar;
  border-radius: 2.4rem;
  flex: 1;
  width: 100%;
  overflow-y: scroll;
  box-shadow: inset 0 0 30px #edfdf91a;
}

.Export-section .value::-webkit-scrollbar {
  display: none;
}

.Export-section .value .code {
  white-space: pre-wrap;
  white-space: -moz-pre-wrap;
  white-space: -o-pre-wrap;
  word-wrap: break-word;
  color: #e0e0e0;
  padding: 1.4rem;
  font-family: Roboto Mono, monospace;
  font-size: 1.4rem;
  overflow: auto;
}

.Export-params {
  flex-direction: column;
  padding-top: 1.3rem;
  display: flex;
}

.Export-params-scheduleTime {
  margin: 8px 0 4px !important;
}

.Export-params .MuiTextField-root {
  width: 100%;
  font-size: 1.8rem;
}

.Export-params .MuiTextField-root p.Mui-error {
  color: #f6a7b2;
  font-family: Titillium Web, sans-serif;
  font-size: 1.4rem;
  font-weight: 800;
}

.Export-params .MuiTextField-root p.Mui-error:before {
  content: "Error: ";
}

.Export-params .MuiTextField-root label {
  font-family: Titillium Web, sans-serif;
  font-weight: 800;
}

.Export-params .MuiTextField-root label.Mui-focused {
  color: #e0e0e0;
}

.Export-params .MuiTextField-root label.Mui-error {
  color: #f6a7b2;
}

.Export-params .MuiTextField-root .MuiOutlinedInput-root input, .Export-params .MuiTextField-root .MuiOutlinedInput-root textarea {
  color: #edfdf9;
  font-family: Roboto Mono, monospace;
  font-size: 1.4rem;
}

.Export-params .MuiTextField-root .MuiOutlinedInput-root textarea {
  scrollbar-width: none;
  -ms-overflow-style: -ms-autohiding-scrollbar;
  white-space: pre;
  overflow-x: scroll !important;
}

.Export-params .MuiTextField-root .MuiOutlinedInput-root textarea::-webkit-scrollbar {
  display: none;
}

.Export-params .MuiTextField-root .MuiOutlinedInput-root fieldset {
  border-width: 1px;
  border-color: #a4bab8;
  border-radius: .6rem;
  box-shadow: inset 0 0 30px #edfdf91a;
}

.Export-params .MuiTextField-root .MuiOutlinedInput-root fieldset legend {
  visibility: visible;
  background-color: #323a3c;
  border-radius: 20px;
  padding: .15rem;
}

.Export-params .MuiTextField-root .MuiOutlinedInput-root:hover fieldset, .Export-params .MuiTextField-root .MuiOutlinedInput-root.Mui-focused fieldset {
  border-color: #edfdf9;
}

.Export-params .MuiTextField-root .MuiOutlinedInput-root.Mui-error fieldset {
  border-color: #f6a7b2 !important;
}

.Export-params .MuiTextField-root.roundtop fieldset {
  border-top-left-radius: 1.8rem !important;
  border-top-right-radius: 1.8rem !important;
}

.Export-params .MuiTextField-root.roundbottom fieldset {
  border-bottom-right-radius: 1.8rem !important;
  border-bottom-left-radius: 1.8rem !important;
}

.Export-params .MuiTextField-root :not(input) {
  color: #e0e0e0;
}

.Export-errors {
  width: 100%;
}

.Export-errors .header {
  padding-left: 1.4rem;
}

.Export-errors .header h3 {
  color: #f6a7b2;
  text-align: left;
  font-size: 1.8rem;
}

.Export-errors .error-list {
  border-radius: 2.4rem;
  flex-flow: column;
  width: 100%;
  padding-top: 1.4rem;
  display: flex;
  box-shadow: inset 0 0 30px #edfdf91a;
}

.Export-errors .error-list .error {
  color: #f6a7b2;
  flex-flow: row;
  justify-content: space-between;
  align-items: center;
  gap: 1.4rem;
  width: 100%;
  max-width: calc(100% - 2.8rem);
  padding: 0 1.4rem 1.4rem;
  font-size: 1.8rem;
  font-weight: 800;
  display: flex;
}

.Export-errors .error-list .error .msg {
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
}

.Export-errors .error-list .error .icon {
  color: #f6a7b2;
  cursor: pointer;
  font-size: 2.3rem;
}

.Export-errors .error-list .error .icon:hover, .Export-errors .error-list .error .icon:focus {
  color: #dcefea;
}

.MuiMenuItem-root {
  font-size: 1.4rem !important;
}

.Menu {
  z-index: 2;
  background-color: #323a3c;
  border-radius: 4.2rem;
  width: 0;
  min-width: 39.6rem;
  height: calc(100% - 5.6rem);
  transition: width .2s ease-out;
  position: fixed;
  top: 2.8rem;
  right: 2.8rem;
  box-shadow: 1px 10px 20px #0003;
}

.Menu-tabs {
  height: calc(100% - 2.8rem);
}

.Menu-tabs-buttonsPanel {
  border-radius: 2.4rem;
  width: calc(100% - 5.6rem);
  margin: 2.8rem;
  box-shadow: inset 0 0 30px #edfdf91a;
}

.Menu-tabs-buttonsPanel .Tabs-item-button {
  flex: 1;
}

.Menu-tabs-contentSpace .Tabs-item-panel.is-active {
  flex-flow: column;
  gap: 2.8rem;
  height: calc(100% - 3.92rem);
}

.Menu-tabs-contentSpace .Tabs-item-panel.is-active > div {
  flex-flow: column;
  width: calc(100% - 5.6rem);
  height: calc(100% - 2.8rem);
  padding: 0 2.8rem 2.8rem;
  display: flex;
}

.Menu--expanded {
  width: 50%;
  transition: width .2s ease-out;
}

.toggle-size {
  width: 5.1rem;
  transition: transform .2s linear;
  position: absolute;
  top: calc(50% - 1.15rem);
  left: -2.55rem;
}

.toggle-size .icon {
  scale: 1.2;
}

.toggle-size.expand {
  transform: scaleX(1);
}

.toggle-size.expand .icon {
  color: #a4bab8;
  font-size: 2.3rem;
}

.toggle-size.expand .icon:hover, .toggle-size.expand .icon:focus {
  color: #637171;
}

.toggle-size.collapse {
  transform: scaleX(-1);
}

.toggle-size.collapse .icon {
  color: #a4bab8;
  font-size: 2.3rem;
}

.toggle-size.collapse .icon:hover, .toggle-size.collapse .icon:focus {
  color: #dcefea;
}

.layout-wrapper {
  flex-flow: row;
  width: calc(100% - 5.6rem);
  height: 100%;
  display: flex;
  position: relative;
  left: 5.6rem;
}

.layout-wrapper:before {
  content: "";
  background-color: #b4ccca;
  min-width: 100%;
  height: 6.75rem;
  position: fixed;
  top: 0;
  left: 0;
}

.layout-wrapper .layout-container {
  flex-flow: row;
  flex: 1 1 0;
  justify-content: center;
  align-items: flex-start;
  width: auto;
  height: 100%;
  margin: 0;
  padding: 0 0 0 2.8rem;
  display: flex;
  position: relative;
}

.layout-wrapper .layout-container[tutorial="yes"]:before {
  content: " ";
  opacity: .2;
  filter: none;
  background-image: url("sequence.1ded890c.svg");
  background-position: 0;
  background-repeat: no-repeat;
  background-size: contain;
  width: 28%;
  height: 40vh;
  transition: all 1s;
  position: absolute;
  top: 30vh;
  left: 15%;
}

.layout-wrapper .layout-container[tutorial="no"]:before {
  content: " ";
  opacity: .2;
  filter: blur(5px);
  opacity: 0;
  background-image: url("sequence.1ded890c.svg");
  background-position: 0;
  background-repeat: no-repeat;
  background-size: contain;
  width: 28%;
  height: 40vh;
  transition: all 1s;
  position: absolute;
  top: 30vh;
  left: 15%;
}

.layout-wrapper .layout-container[tutorial="yes"]:after {
  content: " ";
  opacity: .2;
  filter: none;
  background-image: url("parallel.b26b0a3c.svg");
  background-position: 100%;
  background-repeat: no-repeat;
  background-size: contain;
  width: 35%;
  height: 40vh;
  transition: all 1s;
  position: absolute;
  top: 30vh;
  right: 15%;
}

.layout-wrapper .layout-container[tutorial="no"]:after {
  content: " ";
  opacity: .2;
  filter: blur(5px);
  opacity: 0;
  background-image: url("parallel.b26b0a3c.svg");
  background-position: 100%;
  background-repeat: no-repeat;
  background-size: contain;
  width: 35%;
  height: 40vh;
  transition: all 1s;
  position: absolute;
  top: 30vh;
  right: 15%;
}

.layout-wrapper .empty-container {
  flex-shrink: 0;
  flex-basis: 45.2rem;
  transition: flex-basis .2s ease-out;
}

.layout-wrapper .empty-container.expanded-empty {
  flex-basis: calc(5.6rem + 50%);
  transition: flex-basis .2s ease-out;
}

/*# sourceMappingURL=app.4c241a34.css.map */
