$green: #93c99e;
$purple: #d8beda;
$red: #f6a7b2;
$blue: #b2dff2;
$yellow: #ffdf82;

$lightest: #dcefea;
$light: #b4ccca;
$lightish: #a4bab8;
$darkish: #839595;
$dark: #637171;
$darkest: #424c4e;

$white: #edfdf9;
$black: #323a3c;

$text: #2a2a2a;
$light-text: #e0e0e0;
