@use "sass/font";
@use "sass/color";
@use "sass/size";

.DateTimePicker {
    &-modal {
        border-radius: size.$task-radius !important;

        * {
            font-family: font.$text !important;
            font-size: size.$text !important;
        }

        .MuiYearPicker-root {
            button {
                padding: 0;
            }
        }

        .MuiClock-pin,
        .MuiClockPointer-root,
        .MuiClockPointer-thumb,
        .MuiPickersDay-root.Mui-selected,
        .PrivatePickersYear-yearButton.Mui-selected {
            background-color: color.$black !important;
            border-color: color.$black;
        }
    }

    &-input {
        .MuiInputBase-root {
            padding: 0;
        }
        .MuiInputAdornment-root {
            position: absolute;
            right: 0;
            .MuiIconButton-root {
                padding: 8px;
                margin: 6px;
            }
        }
    }
}
